
<template>
  <div id="top_nav">
    <b-navbar>
        <b-navbar-brand class="nav_title" href="/">
            <span class="menu_text" @class="pageBack"><b-icon icon="chevron-left" class="mr-0" font-scale="1.5"></b-icon></span>
            <p class="mb-0">{{ nav }}</p>
            <p class="mb-0"></p>
        </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterTab: false,
    }
  },
  props: {
    nav: {
      type: String,
      default: ""
    },
  },
  methods: {
    pageBack: function() {
      window.history.back();
    }
  }
}
</script>

<style scoped lang="scss">
#top_nav{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #D3D8E0;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
}
nav{
  color: #333;
  background-color: white;
}
/deep/ .nav-link {
  padding: 0rem 0.5rem;
}
.navbar-brand{
  font-weight: bold;
}
.nav_title {
  width: 100%;
  text-align: center;
  font-size: 1.0rem !important;
  padding: 10px 0px !important;
  margin: 0px !important;
}
.navbar {
  padding: 5px 5px;
  display: block;
}
input.form-control {
  background-color: #F0F0F0 !important;
  border-color: #848484;
  padding: 0.3rem 0.6rem;
  font-size: 0.7rem;

}
input::placeholder {
  color: #C8C8C8 !important;
}
/deep/ .nav-menu {
  display: flex;
  justify-content: space-between;

  /deep/ .nav-link {
    padding: 0px;
  }
}
.logo_img {
  width: 8%;
}
.w100 {
  width: 50%;
  margin: auto;
}
/deep/ li.menu-link .nav-link{
  padding: 0px;
}
.w15 {
  width: 16%;
}
.w10 {
  width: 10%;
}
.filter-link {
  color: #333;
  font-size: 15px;
  padding: 0px 5px;
}


@media screen and (max-width: 896px) {
  .logo_img {
    width: 22%;
  }

  .w100 {
    width: 100%;
    margin: 0px;
  }
  li.menu-link {
    padding: 0px;
    margin: 0px 5px;
  }
  .filter {
    margin: 0px 10px;
  }

}
/deep/ .nav-tabs {
  border: none;
  .nav-link.active {
    color: #7DC5C1 !important;
    border-bottom: 2px solid #7DC5C1;
  }
  .nav-link {
    border: none;
    color: #525252 !important;
    font-weight: 600;
    font-size: 14px;
  }

}
/deep/ .tab-content {
  margin-top: 0px;
}
.filter-content {
   background-color: #E5E5E5;
   padding: 15px 0px;
   .col {
     background-color: #E5E5E5;
   }
}
.bg-gray {
   background-color: #E5E5E5;
}
.navbar-brand {
   justify-content: space-between;
   display: flex
}
</style>
