
<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header></simple-text-header>
    <div class="text-left px-2 home-container">
      <b-row>
        <b-col cols="12" class="title-header px-1 mb-3">
          <h3>基本情報</h3>
         </b-col>
        <b-col cols="6" class="py-2">
          <label class="form-label">お名前</label>
        </b-col>
        <b-col cols="5" class="px-1">
            <p>{{ influencer.first_name }}  {{ influencer.last_name }}</p>
        </b-col>
        <b-col cols="1" class="px-1">
            <p>></p>
        </b-col>
          
        <b-col cols="6" class="py-2">
          <label class="form-label">フリガナ<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.first_kana_name" type="text" required />
          <p class="input-description mb-2 mt-1">例) タナカ</p>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.last_kana_name" type="text" />
          <p class="input-description mb-2 mt-1">例) ユウコ</p>
        </b-col>
          
        <b-col cols="6" class="py-2">
          <label class="form-label">メールアドレス<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.email" type="text" required />
          <p class="input-description mb-2 mt-1">例) info@ouvo.co.jp</p>
        </b-col>

        <b-col cols="6" class="py-2">
          <label class="form-label">電話番号<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input type="tel" class="ouvo-input" v-model="form.phone_number" required pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"/>
          <p class="input-description mb-2 mt-1">例) 080-1234-1234</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">郵便番号<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" @change="updateZip" v-model="form.zip" type="number" required />
          <p class="input-description mb-2 mt-1">例) 1234567</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">住所<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.address1" type="text" required />
          <p class="input-description mb-2 mt-1">例) 東京都港区芝公園1-2-3</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">建物名<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.address2" type="text" required />
          <p class="input-description mb-2 mt-1">例) シバマンション101</p>
        </b-col>
  
        <b-col cols="6" class="py-2">
          <label class="form-label">生年月日<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.birthday" type="date" required />
          <p class="input-description mb-2 mt-1">例) 1993/01/01</p>
        </b-col>
  
        <b-col cols="6" class="py-2">
          <label class="form-label">性別<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-select class="ouvo-input" v-model="form.gender" :options="gender"></b-form-select>
        </b-col>

        <b-col cols="12" class="title-header px-3 mb-3">
          <h3>SNS情報</h3>
         </b-col>

        <b-col cols="6" class="py-2">
          <label class="form-label">Instagram<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.instagram_id" type="text" placeholder="ouvo" />
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">Twitter<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.twitter_id" type="text" placeholder="ouvo" />
        </b-col>
        <b-col cols="12" class="text-center">
          <b-button @click="onSubmit" class="ouvo-button my-2">登録する</b-button>
        </b-col>
      </b-row>
    </div>

    <amplify-sign-out button-text="サインアウト"></amplify-sign-out>
  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'
let YubinBango = require('yubinbango-core2')

import axios from 'axios'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'InfluencerForm',
  components: {
    SimpleTextHeader
  },
  data() {
    return{
      authUser: "",
      userId: "",
      authState: "",
      influencer: {},
      gender: ['男性', '女性', 'その他'],
      show: true,
      idToken: localStorage.getItem('idToken')
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: { Authorization: `Bearer ${this.idToken}`}})
      .then((res) => {
        this.influencer = res.data
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    updateZip: function() {
      new YubinBango.Core(this.form.zip, (addr) => {
          this.form.address1 = addr['region'] + addr['locality'] + addr['street']

      })
    },
    onSubmit() {
      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/influencers", { influencer: this.form }, 
                { headers: { Authorization: `Bearer ${this.idToken}`, 
                             'Content-Type': 'application/json' }
        })
        .then((res) => {
          console.log(res.data)
        }).catch(error => {
          console.log(error)
        })
    },
  }
}
</script>

<style>
.required-label {
  background: #033E68;
  color: white;
  font-size: 10px;
}
.form-label {
  font-size: 12px;
}
.input-description {
  color: #CACACA;
  font-size: 10px;
  font-weight: 500;
}
</style>

