<template>
  <div id="transparent_nav">
    <b-navbar>
        <b-navbar-brand class="text-left" @click="page_back">
            <b-icon icon="chevron-left"></b-icon>
        </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filterTab: false,
    }
  },
  props: {

  },
  mounted() {
  },
  methods: {
    page_back() {
      window.history.back();
    }
  },
}
</script>

<style scoped lang="scss">
#transparent_nav{
  position: absolute;
  background: rgba(0,0,0,0);
  background-color: transparent;
  z-index: 10000;
  top: 0;
  left: 0;
  text-align: center;
}


</style>
