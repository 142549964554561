<template>
  <div id="top_nav">
    <b-navbar>
        <b-navbar-brand class="nav_title" href="/">
          <img class="logo_img" src="../assets/logo.png" alt="Kitten">
        </b-navbar-brand>
        <b-nav-item class="w100">
          <b-input v-model="freeword" placeholder="募集中の案件を探す"></b-input>
        </b-nav-item>
    </b-navbar>
    <b-navbar class="nav-menu w100">
        <b-nav-item class="menu-link text-left filter" @click="switchFilterTab()" style="padding-bottom: 10px;">
          <img class="w15" src="../assets/icons/32/ouvo_icon_search_black.png" />
          <span class="filter-link">絞り込む</span>
          <img class="w10" src="../assets/icons/32/ouvo_icon_under_black.png" />
        </b-nav-item>
        <b-nav-item class="menu-link" @click="onClickSwitch">
          <img :src="getModeIcon(mode)" />
        </b-nav-item>
    </b-navbar>
    <b-navbar v-show="filterTab" class="px-0 py-0">
      <b-tabs content-class="mt-0" align="center">
        <b-tab title="投稿先SNS" class="" active>
            <b-row class="filter-content">
              <b-col>
                  <span class="ouvo-tag" @click="onClickSwitchTab('Instagram')" :class="isActiveTab('Instagram')">Instagram</span>
              </b-col>
              <b-col>
                  <span class="ouvo-tag" @click="onClickSwitchTab('Twitter')" :class="isActiveTab('Twitter')">Twitter</span>
              </b-col>
            </b-row>
            <b-row class="filter-content py-1">
              <b-col class="text-center" @click="switchFilterTab()">
                 <b-icon icon="chevron-up" font-scale="1.5" class="bg-gray"></b-icon>
              </b-col>
            </b-row>
        </b-tab>
        <b-tab title="カテゴリー">
            <b-row class="filter-content px-3 py-2">
                <div v-for="c in categories" :key="c" class=" bg-gray my-1 py-1" @click="onClickCategory(c)">
                  <span class="ouvo-tag px-3 mx-1" :class="isActiveCategoryTab(c)">{{ c }}</span>
                </div>
            </b-row>
            <b-row class="filter-content py-1">
              <b-col class="text-center" @click="switchFilterTab()">
                 <b-icon icon="chevron-up" font-scale="1.5" class="bg-gray"></b-icon>
              </b-col>
            </b-row>
        </b-tab>
        <b-tab title="募集条件">

            <b-row class="filter-content px-3 py-2">
                <p class="condition_title mx-1 mb-0 mt-2">フォロワー数</p>
                <div v-for="c in followers" :key="c['value']" class="bg-gray my-1 py-1" @click="onClickWordCondition('follower', c['value'])">
                  <span class="ouvo-tag px-3 mx-1" :class="isActiveWordCondition('follower', c['value'])">{{ c['label'] }}</span>
                </div>
                <p class="condition_title mx-1 mb-0 mt-2">募集人数</p>
                <div v-for="c in nums" :key="c['value']" class=" bg-gray  my-1 py-1" @click="onClickWordCondition('nums', c['value'])">
                  <span class="ouvo-tag px-3 mx-1" :class="isActiveWordCondition('nums', c['value'])">{{ c['label'] }}</span>
                </div>
                <p class="condition_title mx-1 mb-0 mt-2">年齢</p>
                <div v-for="c in ages" :key="c['value']" class=" bg-gray  my-1 py-1" @click="onClickCondition('ages', c['value'])">
                  <span class="ouvo-tag px-3 mx-2" :class="isActiveAges(c['value'])">{{ c['label'] }}</span>
                </div>
                <p class="condition_title mx-1 mb-0 mt-2">来店・体験</p>
                <div v-for="c in experiences" :key="c" class=" bg-gray  my-1 py-1" @click="onClickWordCondition('experience', c)">
                  <span class="ouvo-tag px-3 mx-2" :class="isActiveWordCondition('experience', c)">{{ c }}</span>
                </div>
            </b-row>
            <b-row class="filter-content py-1">
              <b-col class="text-center" @click="switchFilterTab()">
                 <b-icon icon="chevron-up" font-scale="1.5" class="bg-gray"></b-icon>
              </b-col>
            </b-row>
        </b-tab>
      </b-tabs>
        
    </b-navbar>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      filterTab: false,
      currentTab: [],
      categories: ['美容', 'ファッション', '食品', '家電', '雑貨', 'インテリア', '料理器具'],
      followers: [
        {value: 1000, label: "1,000〜"},
        {value: 3000, label: "3,000〜"},
        {value: 5000, label: "5,000〜"},
        {value: 10000, label: "10,000〜"},
        {value: 20000, label: "20,000〜"},
      ],
      nums: [
        {value: 10, label: "~10名"},
        {value: 50, label: "~50名"},
        {value: 100, label: "~100名"},
        {value: 101, label: "101名~"},
      ],

      ages: [
        {value: "10", label: "10代"},
        {value: "20-29", label: "20代"},
        {value: "30-39", label: "30代"},
        {value: "40-", label: "40代~"},
      ],
      idToken: localStorage.getItem('idToken'),
      experiences: ["はい", "いいえ"],
      currentCategories: [],
      conditions: {},
      currentAges: [],
      influencer: {},
      freeword: ""
    }
  },
  props: {
    nav: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: "gallery"
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.influencer = res.data
        this.conditions['follower'] = this.influencer.condition_follower
        this.conditions['nums'] = this.influencer.condition_num
        this.conditions['experience'] = this.influencer.condition_experience

        this.$set(this.conditions, "experience", this.influencer.condition_experience)
        this.$set(this.conditions, "follower", this.influencer.condition_follower)
        this.$set(this.conditions, "nums", this.influencer.condition_num)
        this.currentCategories = this.influencer.condition_category.split('-')
        this.currentTab = this.influencer.condition_post_type.split('-')
        this.currentAges = this.influencer.condition_age.split('=')
      }).catch(error => {
        console.log(error)
      })
  },
  watch: {
    freeword: function() {
      this.filterFreewordJob()
    }
  },
  methods: {
    switchFilterTab: function() {
      this.filterTab = !this.filterTab
    },
    filterFreewordJob: function() {
      this.$emit('fetchJobs', '', "", this.freeword)
    },
    getModeIcon: function(mode) {
      if(mode == "gallery") {
        return require("../assets/icons/32/ouvo_icon_list_s_black.png")
      } else {
        return require("../assets/icons/32/ouvo_icon_PC_s_black.png")
      }
    },
    onClickSwitchTab(m) {
      var i = this.currentTab.indexOf(m)
      if(i > -1) {
        this.currentTab.splice(i, 1)
      } else {
        this.currentTab.push(m)
      }
      this.$emit('fetchJobs', 'media', this.currentTab.join("-"))
    },
    onClickCondition(c, v) {
      var i = this.currentAges.indexOf(v)
      if(i > -1) {
        this.currentAges.splice(i, 1)
      } else {
        this.currentAges.push(v)
      }
      this.$emit('fetchJobs', "ages", this.currentAges.join("="))
    },
    onClickWordCondition(c, v) {
      this.conditions[c] = v
      this.conditions = Object.assign({}, this.conditions, { v });
      this.$emit('fetchJobs', c, this.conditions[c])
    },
    onClickCategory(c) {
      var i = this.currentCategories.indexOf(c)
      if(i > -1) {
        this.currentCategories.splice(i, 1)
      } else {
        this.currentCategories.push(c)
      }
      this.$emit('fetchJobs', "category", this.currentCategories.join("-"))
    },
    isActiveTab: function(m) {
      return (this.currentTab.indexOf(m) > -1)? "ouvo-tag-active" : ""
    },
    isActiveWordCondition: function(c, v) {
      return (this.conditions[c] == v)?  "ouvo-tag-active" : ""
    },
    isActiveConditionTab: function(c, v) {
      if(this.conditions[c] == undefined) {
        this.conditions[c] = []
        return ""
      } else {
        return (this.conditions[c].indexOf(v) > -1)? "ouvo-tag-active" : ""
      }
    },
    isActiveAges: function(c) {
      return (this.currentAges.indexOf(c) > -1)? "ouvo-tag-active" : ""
    }, 
    isActiveCategoryTab: function(c) {
      return (this.currentCategories.indexOf(c) > -1)? "ouvo-tag-active" : ""
    },
    onClickSwitch: function() {
      if(this.mode == "gallery") {
        this.mode = "list"
      } else {
        this.mode = "gallery"
      }
      this.$emit('switchMode', this.mode);
    }
  },
}
</script>

<style scoped lang="scss">
#top_nav{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #D3D8E0;
  z-index: 10000000000;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
}
nav{
  color: #333;
  background-color: white;
}
/deep/ .nav-link {
  padding: 0.2rem 0.3rem;
}
.navbar-brand{
  font-weight: bold;
}
.nav_title {
  width: 100%;
  text-align: center;
  font-size: 1.0rem !important;
  padding: 10px 0px !important;
  margin: 0px !important;
}
.navbar {
  padding: 5px 5px;
  display: block;
}
input.form-control {
  background-color: #F0F0F0 !important;
  border-color: #848484;
  padding: 0.3rem 0.6rem;
  font-size: 0.9rem;

}
input::placeholder {
  color: #C8C8C8 !important;
}
/deep/ .nav-menu {
  display: flex;
  justify-content: space-between;

  /deep/ .nav-link {
    padding: 0px;
  }
}
.logo_img {
  width: 8%;
}
.w100 {
  width: 50%;
  margin: auto;
}
/deep/ li.menu-link .nav-link{
  padding: 0px;
}
.w15 {
  width: 16%;
}
.w10 {
  width: 10%;
}
.filter-link {
  color: #333;
  font-size: 15px;
  padding: 0px 5px;
  font-weight: 600;
}


@media screen and (max-width: 896px) {
  .logo_img {
    width: 22%;
  }

  .w100 {
    width: 100%;
    margin: 0px;
  }
  li.menu-link {
    padding: 0px;
    margin: 0px 5px;
  }
  .filter {
    margin: 0px 10px;
  }

}
/deep/ .nav-tabs {
  border: none;
  .nav-link.active {
    color: #7DC5C1 !important;
    border-bottom: 2px solid #7DC5C1;
  }
  .nav-link {
    border: none;
    color: #525252 !important;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 25px;
  }

}
/deep/ .tab-content {
  margin-top: 0px;
}
.filter-content {
   background-color: #E5E5E5;
   padding: 15px 0px;
   .col {
     background-color: #E5E5E5;
   }
}
.bg-gray {
   background-color: #E5E5E5;
}
.condition_title {
  width: 100%;
  font-size: 12px;
  border-left: 6px solid #70CBC9;
  text-align: left;
  padding: 0px 5px;
  font-weight: 800;

}
</style>
