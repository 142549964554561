<template>
  <div>
    <top_nav></top_nav>
    <div>
      <b-container fluid="sm" class="remove-wrapper">
        <b-row>
          <b-col cols="12">
            <b-card title="辞退の注意">
              <b-card-text>
                辞退の注意書き
              </b-card-text>
              <b-form @submit.prevent="onSubmit">
                <b-button type="submit" variant="danger">辞退する</b-button>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    
    <div class="links">
      <b-row>
        <b-col cols="12">
          <b-button class="mb-3"><router-link :to="{ path: '/' }">トップページ</router-link></b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import top_nav from '@/components/Header.vue'

import axios from 'axios'

export default {
  name: 'RemoveApplication',
  components: {
    top_nav
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      applicate: {},
      form: {},
      sns: [{ text: '選択してください', value: null },'Instagram', 'Twitter']
    }
  },
  methods: {
    onSubmit(){
      this.form.applicate_state = 'canceled'
      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/applicates/" + this.$route.params.id, { applicate: this.form }, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then(() => {
          this.$router.push({ path: '/confirm' })
        }).catch(error => {
          console.log(error)
        })
    }
  },
}
</script>

<style scoped lang="scss">
p {
  font-size: 14px;
}
.remove-wrapper{
  margin-top: 6rem;
}
form{
  background-color: #fff;
}
.card{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
}
.card.border-title{
  border: none;
}
.card-body, .card-title, .card-text{
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
}
.btn.btn-secondary.btn-gray {
  background-color: #A6B2B7;
  border-color: #A6B2B7;
}
.links{
  margin: 15px auto;
}
</style>
