<template>
  <div class="transparent_app">
    <transparent-header></transparent-header>
    <div>
      <b-container fluid="sm" class="job-detail-container">
        <b-row>
          <b-col cols="12" class="px-0">
            <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="480">
              <b-carousel-slide v-for="img in arraySplit(applicate.images)" :key="img" :img-src="img"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="12" class="text-area float-left px-3">
            <div class="job-title w-100 py-1"><router-link :to="'jobs'">{{ applicate.title }}</router-link></div>
            <hr class="my-1"/>

            <b-row class="text-center" v-show="app_state == 'applied'">
                <p class="applied_text ouvo-color my-4">{{ appliedStateText() }}</p>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈応募状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="applicateIconPath()" alt="Kitten">
                  <p class="text-center" :class="applicateClass()"><b>当選</b></p>
                </b-col>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈配送状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="manageIconPath()" alt="Kitten">
                  <p class="text-center" :class="manageClass()"><b>{{ manageStateText() }}</b></p>
                </b-col>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈投稿状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="postIconPath()" alt="Kitten">
                  <p class="text-center" :class="postClass()"><b>{{ postStateText() }}</b></p>
                </b-col>
            </b-row>

          </b-col>
          <b-col v-show="isIncludeMedia('post')" cols="12" class="title-header submit_post d-flex justify-content-between align-items-center">
            <h3>Instagram通常投稿を提出する</h3>
          </b-col>
          <b-col v-show="isIncludeMedia('post')" cols="12" class="instagram-post-section px-3">
            <p class="pt-3 mb-1">投稿したInstagramのURLをペーストする</p>
            <b-form-input class="mb-3" v-model="form.post_url" @update="shapeUrl" type="text" placeholder="" required></b-form-input>
            <iframe v-show="isCorrectUrl(form.post_url)" :src="form.post_url + 'embed'" width="100%" height="400" frameborder="0" scrolling="yes" allowtransparency="true"></iframe>

          </b-col>
          <hr/>
          <b-col v-show="isIncludeMedia('post')"  cols="12" class="title-header d-flex justify-content-between align-items-center">
            <h3>Instagramフィードの注意点</h3>
          </b-col>
          <b-col v-show="isIncludeMedia('post')"  cols="12" class="job-section-body px-3" v-if="openSection['instagram_feed_caution']">
            <b-row class="product-form px-3 py-3">
              <p class="caution"><b>【画像】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(applicate.instagram_caution_image)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-row class="product-form px-3 py-3">
              <p class="caution"><b>【文章】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(applicate.instagram_caution_caption)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-table stacked class="default-table" :items="caution_details" :fields="caution_fields">
                <template v-slot:cell(official_account)="">
                  <a class="table_link" :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>

            <b-col cols="12" class="py-4 text-center">
                <b-form-checkbox v-model="feedConfirmStates">
                  上記条件を満たした投稿ですか？
                </b-form-checkbox>
            </b-col>

          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="title-header submit_post d-flex justify-content-between align-items-center">
            <h3>Instagramのストーリーズを提出する</h3>
          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="job-section-body px-3"  v-if="openSection['instagram_story_caution']">
              <label class="upload-label ouvo-active-square-button my-3 text-left">
                投稿したInstagramのストーリーズの<br/>画像をアップロードする
                <input type="file" @change="onFileChange">
              </label>
              <img
                v-show="uploadedImage"
                class="preview-item-file pb-2"
                :src="uploadedImage"
                alt=""
              />

          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('instagram_story_caution')" v-if="isShowState()">
            <h3>ストーリーズ投稿の注意点</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="job-section-body px-3"  v-if="openSection['instagram_story_caution']">
            <b-row class="product-form px-3 py-3">
              <p class="caution">{{ applicate.instagram_cation_story }}</p>
            </b-row>
            <b-table stacked class="default-table" :items="story_caution_details" :fields="story_caution_fields">
                <template v-slot:cell(story_url)="data">
                  <a class="table_link"  :href="data.item.story_url">{{ data.item.story_url }}</a>
                </template>
                <template v-slot:cell(official_account)="">
                  <a class="table_link" :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>
            <b-form-checkbox v-model="storyConfirmStates" class="pt-3">
              上記条件を満たした投稿ですか？
            </b-form-checkbox>

          </b-col>

          <b-col cols="12" class="descs-wrapper">
            <p class="text-center mb-0 mt-2"><small>チェックボックスを確認し</small></p>
            <b-button @click="onSubmit"  class="ouvo-active-button my-0 mt-2">投稿を提出</b-button>
          </b-col>
          <b-col cols="12" class="descs-wrapper">
            <b-button class="ouvo-button mb-3" @click="backPage">戻る</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-modal id="repost_caution" hide-footer centered>
        <div class="d-block text-center pb-4 px-3 pt-0">
          <p>Instagram投稿の下記の条件が満たされていませんでした。お手数ですが、再投稿をお願いします。</p>
          <p class="text-red">3枚以上投稿してください</p>
        </div>
    </b-modal>
  </div>
</template>

<script>
import TransparentHeader from '@/components/TransparentHeader.vue'

import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ApplicationDetail',
  components: {
    TransparentHeader
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      app_state: "applied",
      manage_state: "delivered",
      post_state: "not_posted",
      feedConfirmStates: false,
      storyConfirmStates: false,
      form: {url: "", post_type: "instagram"},
      org: {},
      snsOptions: [
        { text: '選択してください', value: null , disabled: true },
        { text: 'Instagram', value: 'instagram' },
        { text: 'Twitter', value: 'twitter' }
      ],
      applicate: {},
      openSection: {
        "description": false,
        "request": false,
        "instagram_feed_caution": false,
        "instagram_story_caution": false,
        "caution": false,
      },
      request_details: [],
      request_fields: [
          {key: 'media', label: '稼働'},
          {key: 'service', label: '商品・サービス'},
          {key: 'experience_place', label: '体験場所'},
          {key: 'instagram_caution', label: 'Instagram投稿'},
          {key: 'instagram_story_caution', label: 'ストーリー投稿'},
          {key: 'experience_count', label: '体験回数'},
          {key: 'post_at', label: '投稿期間'},
      ],
      caution_details: [],
      caution_fields: [
          {key: 'must_hashtags', label: '必須ハッシュタグ'},
          {key: 'recommend_hashtags', label: '推奨ハッシュタグ'},
          {key: 'official_account', label: '公式アカウント'},
      ],
      story_caution_fields: [
          {key: 'story_url', label: 'URL'},
          {key: 'official_account', label: '公式アカウント'},
      ],
      uploadedImage: '',
      img_name: '',
    }
  },
  created() {
    this.form = {
        url: ""
    }
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/applicates/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.applicate = res.data.applicate
        this.org = res.data.org
        var m = ""
        Array.from(new Set((this.applicate.media || '').split(","))).forEach(e => {
            if(e == "story") {
              m += " * Instagramストーリー投稿\n"
            }
            if(e == "post") {
              m += " * Instagram通常投稿"
            }
        })
        this.request_details = [
            {media: m, service: this.applicate.service, experience_place: this.applicate.experience_place, experience_count: this.applicate.experience_count}
        ]
        this.caution_details = [
            {must_hashtags: this.applicate.must_hashtags, recommend_hashtags: this.applicate.recommend_hashtags, official_account: this.applicate.official_account}
        ]
        this.story_caution_details = [
            {story_url: this.applicate.story_url, official_account: this.applicate.official_account}
        ]

        this.setOpenSection()


      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    onClickOpenSection(c) {
        this.openSection[c] = !this.openSection[c]
    },
    arraySplit(s) {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    },
    isIncludeMedia(s) {
      var st = this.arraySplit(this.applicate.media)
      if(st != undefined) {
        return (st.indexOf(s) > -1)? true : false
      }
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.createImage(files[0]);
      this.img_name = files[0].name;
    },
    // アップロードした画像を表示
    createImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.uploadedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    shapeUrl() {
      if(this.form.post_url.includes("?")) {
        this.form.post_url = this.form.post_url.split("?")[0]
      }
    },
    isCorrectUrl(url) {
      url = url || ""
      if(url != "") {
        return true
      } else {
        return false
      }
    },
    setOpenSection() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        this.openSection["description"] = true
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        this.openSection["request"] = true
        this.openSection["instagram_feed_caution"] = true
        this.openSection["instagram_story_caution"] = true
        this.openSection["caution"] = true
      }
    },
    backPage: function() {
      window.history.back();
    },
    isShowState() {
      if(this.app_state == 'rejected') {
        return false
      } else if(this.app_state == 'applied') {
        return true
      }
    },
    applicateClass() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "ouvo-color"
      }
    },
    manageClass() {
      if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "ouvo-color"
      }
    },
    postClass() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "ouvo-color"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "ouvo-color"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "ouvo-red"
      } else {
        return "ouvo-gray"
      }  
    },
    applicateIconPath() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return require('../../assets/icons/256/ouvo_icon_present.png')
      } else {
        return require('../../assets/icons/256/ouvo_icon_present_black.png')
      }
    },
    postIconPath() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return require('../../assets/icons/256/ouvo_icon_camera.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return require('../../assets/icons/256/ouvo_icon_smartphone.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return require('../../assets/icons/256/ouvo_icon_camera_red.png')
      } else {
        return require('../../assets/icons/256/ouvo_icon_camera_gray.png')
      } 
    },
    manageIconPath() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return require('../../assets/icons/256/ouvo_icon_box_black.png')
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return require('../../assets/icons/256/ouvo_icon_truck.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } 
    },
    manageStateText() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "出荷準備中"
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "配送中"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "配送済"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "配送済"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "配送済"
      } 
    },
    postStateText() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "未提出"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "提出済み"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "再提出"
      } 
      return "未提出"
    },
    appliedStateText() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "当選おめでとうございます。\n商品発送まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "商品を発送中です\n到着まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "商品を確認して\n指定のSNSに投稿をお願いします。"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "投稿内容を確認中です。\n完了まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "posted") {
        return "投稿確認完了"
      }
    },
    onSubmit(){
      this.form.id = this.applicate.id
      if(this.uploadedImage != "") {
        this.storyConfirmStates = true
        if(this.storyConfirmStates == false) {
          alert("注意事項し同意しますにチェックを入れてください")
        } else {
          this.form.story_post = this.uploadedImage
          axios
            .patch(process.env.VUE_APP_API_ENDPOINT + "/applicates/" + this.$route.params.id, { applicate: this.form }, {headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
            .then(() => {
              this.$router.push({ path: '/thanksposting' })
            }).catch(error => {
              console.log(error)
            })
        }
      } else {
        if(this.feedConfirmStates == false) {
          alert("注意事項し同意しますにチェックを入れてください")
        } else {
          axios
            .patch(process.env.VUE_APP_API_ENDPOINT + "/applicates/" + this.$route.params.id, {applicate: this.form}, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
            .then(() => {
              this.$router.push({ path: '/thanksposting' })
            }).catch(error => {
              console.log(error)
            })
        }
      }
    },
    isActivatePost(applicate) {
        if(applicate.applicate_state != "applied") {
          return false
        }
        if(applicate.post_state == "checking") {
          return false
        } else if(applicate.post_state == "not_posted") {
          return true
        }  else if(applicate.post_state == "posted") {
          return false
        }  else if(applicate.post_state == "re-post") {
          return true
        }
        return true
    },
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    showButton(status) {
      const now = moment().format()
      return (status == "submitted" || now <= moment(status).add(3, 'days').format() ? true : false)
    },
    getMedias(m) {
      return Array.from(new Set((m || '').split(",")))
    },
    getIconTitle(j) {
      return this.titles[j]
    },
    formatJobDate(d) {
      var a = moment(d)
      if(a.isValid()) {
          return a.format("YYYY年MM月DD日")
      } else {
          return ""
      }
    },


  }
}
</script>

<style scoped lang="scss">
img.icon{    
  width: 100%;
  height: 17rem;
  object-fit: cover;
}
p {
  text-align: left;
}
.applicate_caution {
  color: red;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
.applicate_state_text {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  width: 100%;
  letter-spacing: 6px;
}
.applicate_repost_text {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  width: 100%;
}
h6.descs-title{
  font-weight: 600;
}
p.job-description{
  font-size: 14px;
}
p.descs-title{
  font-weight: 600;
  margin: 0;
}
p.descs-detail{
  padding-left: 15px;
}
.descs-wrapper{
  margin-top: .3rem;
  margin-bottom: .4rem;
}
.green-btn{
  border: none !important;
  background-color: #9FDC6F !important;
}
.links{
  margin: 15px auto;
}

/deep/ .table {
  color: #575757;
  margin-bottom: 0px;
  th, td {
    padding: 0px;
    font-size: 12px;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      white-space: break-spaces;
    }
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #F0F0F0;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
/deep/ .table.default-table {
  color: #575757;
  th, td {
    padding: 0px;
    font-size: 12px;
    border: 1px solid #333;
    border-bottom: none;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      border-left: 1px solid #333;
      white-space: pre;
      white-space: break-spaces;
    }
  }
  td:first-child {
    border-top-width: 1px !important;
  }
  td:last-child {
    border: 1px solid #333;
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #fff;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
.top_cover_img {
  border-radius: 0px !important;
}
.half-icon {
  width: 5%;
  margin-left: 5px;
  background-color: #F2F2F2;

}

/deep/ .table_link {
  color: #8FC7ED;
}
.bicon {
  background-color: #E5E5E5;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.repost_small_caution {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.small_caution {
  width: 100%;
  font-size: 13px;
  text-align: center;
}
.btn.btn-secondary.btn-gray {
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  font-size: 14px;
  padding: 12px 20px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.2));
  
  a {
    color: #373E45;
    font-weight: 500;
  }
}
.thanks_p{
  color: #7FCAC0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.banner_img {
  width: 70%;
}
.app_state_img {
  width: 85%;
}
.applied_text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  white-space: pre;
}

.app_sub_text {
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.section-title {
  background: #9BDDDB;
  color: #525252;
  font-size: 14px;
  font-weight: 600;
}
.recommend_caption {
  color: #525252;
  border-bottom: 1px solid #D2D2D2;
  text-align: left;
  font-size: 12px;
  line-height: 26px;
  width: 100%;
}
.hoshi_img {
  width: 20%;
  background: #9BDDDB;
}
.text-red {
  font-weight: 500;
  color: red;
}
/deep/ .modal-header {
  border: none !important;
}
/deep/ .modal-content {
  border-radius: 0px;
}
.submit_post {
  background-color: #1DA0E1 !important; 
  h3{
    background-color: #1DA0E1 !important; 
    color: white !important;
  }
}
.instagram-post-section {
  p {
    font-size: 14px;
    font-weight: 800;
    text-align: center;

  }
}
.upload-label input {
  display: none;
}
.preview-item-file {
  width: 100%;
}
</style>








