
<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header  :nav="'やることリスト'"></simple-text-header>
    <b-container v-for="job in filteredJobs" v-bind:key="job.uuid" fluid="sm" class="border_line">
      <b-row v-show="isTodo(job)">
        <b-col cols="3" class="px-0">
          <b-img class="thumbnail" :src="job.images"></b-img>
        </b-col>
        <b-col cols="9" class="text-area float-left pt-1 pr-2 pl-3">
          <div class="float-left job-title mb-0 mt-0">
              <div v-show="isBigIssue(job)"><span class="big_issue py-1 px-2 mt-1 mb-1"> {{ issueText(job) }}</span><br/></div>
              <router-link :to="'/applicationLists/' + job.id">{{ todoText(job) }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="filteredJobs.length == 0" fluid="sm" class="job-container">
      <b-row class="not_found">
        <b-col cols="12">
          <p class="auto px-2 my-5 py-5">現在、やることリストはありません</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'

import axios from 'axios'
import moment from 'moment'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'ApplicationLists',
  components: {
    SimpleTextHeader
  },
  data() {
    return{
      authUser: '',
      idToken: localStorage.getItem('idToken'),
      filteredJobs: [],
      jobFields: [
        { key: 'title', label: 'タイトル' },
        { key: 'applicate_state', label: '応募状況' },
        { key: 'post_url', label: '投稿URL' }
      ],
    }
  },
  mounted() {
    this.currentTabContenet()
  },
  methods: {
    isTodo(job) {
      if(job.manage_state == "delivered" && job.post_state == "not_posted") {
        return true
      } else if(job.manage_state == "delivered" && job.post_state == "re-post") {
        return true
      } else {
        return false
      }
    },
    issueText(job) {
      if(job.manage_state == "delivered" && job.post_state == "re-post") {
        return "再投稿"
      } else {
        return "期日超過"
      }
    },
    isBigIssue(job) {
      if(job.manage_state == "delivered" && job.post_state == "re-post") {
        return true
      } else {
        return false
      }
    },
    todoText(job) {
      if(job.manage_state == "delivered" && job.post_state == "not_posted") {
        return "商品の発送が完了しました。ご確認の上、投稿条件に合わせてSNS投稿いただき、完了次第ご報告お願いします。"
      } else if(job.manage_state == "delivered" && job.post_state == "re-post") {
        return "投稿を確認したところ条件を満たしていない投稿でしたので、メッセージを確認の上、再投稿をお願いします。"
      }
    },
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    currentTabContenet() {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/applicates", { 
          headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}, 
          params: { state: "applied" } 
        })
        .then((res) => {
          this.filteredJobs = res.data.applicate

        }).catch(error => {
          console.log(error)
        })
    }
  },
}
</script>

<style scoped lang="scss">
.btn-wrapper{
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;
}
.gray-btn{
  margin: 0;
  font-weight: 600;
  font-size: 12px;
}
.job-container{    
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.shadow{
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 8px;
}
.text-area{
  padding-bottom: 20px;
  padding-top: 14px;
  background-color: #ffffff;
  border-radius: 8px;
}
.job-title{
  font-weight: 400;
  text-align: left;
  line-height: 1.1;
  margin-top: .7rem;
  margin-bottom: 5px;
  width: 100%;
  background-color: #ffffff;
  a {
    color: #4E4E4E;
    font-size: 11px;
  }

}
.job-content{
  background-color: #ffffff;
  padding: .3rem 0;
}
.current-tab{
  color: #72CDC7;
  border-bottom: 2px solid #72CDC7;
}
.thumbnail{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.btn-apply{
  float: right;
}

@media (min-width: 768px){
  .job-container{
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 768px){
  .job-container{
    max-width: 540px;
  }
}
.border_line {
  border-bottom: 1px solid #595A59;
}
.info-box {
  border: 2px solid #4E4E4E; 
  padding: 5px 0px;
  p {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }
}
.not_found {
  height: 100%;
  p {
    font-weight: 800;
    font-size: 18px;
    color: #CACACA;
  }
}
.big_issue {
  background-color: red;
  color: white;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
}
</style>
