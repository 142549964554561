
<template>
  <div>
    <div>
      <b-container fluid="sm" class="job_application border_bottom">
        <b-row>
          <b-col cols="12" class="p-0">
              <p class="title border p-4 mb-0">
                <span><img class="logo_img" src="../../assets/icons/menu/survey.png" alt="Kitten"></span>
                アンケートにご協力お願いします
              </p>
          </b-col>
        </b-row>
        <b-form>
          <b-form-group v-for="(question, index) in questions" :key="question.id" class="mb-1">
              <p class="title px-0 py-2 mb-0 text-left pt-3 pb-0">Q{{ index+1  }}. {{ question.title }}</p>
            <div v-if="question.q_type == 'radio' || question.q_type == 'pull_down' || question.q_type == 'checkbox'">
              <b-row class="filter-content px-3 py-0">
                <div v-for="c in question.content.split(',')" :key="c" class=" bg-gray my-1 py-1" @click="onClickCategory(c, question.id, question.q_type)">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveTab(c, question.id)">{{ c }}</span>
                </div>
              </b-row>
            </div>

            <div v-if="question.q_type == 'confirmation'">
              <b-form-checkbox-group :required="requireType(question.require_type)">
                <b-form-checkbox
                  v-model="form[question.id]"
                > 
                  {{ question.content }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </b-form-group>
          <b-col cols="12" class="text-center pt-4">
            <b-button @click="onSubmit"  class="ouvo-active-button my-2 mt-4">回答を送信</b-button>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button @click="backPage" class="ouvo-button my-2">戻る</b-button>
          </b-col>
        </b-form>
      </b-container>
    </div>
    
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Application',
  components: {
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      formGroup: '',
      job: {},
      influencer: {},
      form: {},
      answer: {},
      applicates: {},
      questions: {},
      option: {},
      confirmStates: false
    }
  },
  methods: {
    getQuestionName(id) {
      return id.toString()
    },
    requireType(option){
      return (option == 'required') ? true : false
    },
    isActiveTab: function(c, id) {
      return (this.form[id] != undefined && this.form[id].indexOf(c) > -1)? "ouvo-tag-active" : ""
    },
    getOptions(content){
      this.options = content.split(', ')
      return this.options
    },
    backPage: function() {
      window.history.back();
    },
    onClickCategory(c, id, t) {
      if(this.form[id] == undefined) {
        this.form[id] = []
      }
      if(t == "radio" || t =="pull_down") {
        this.form[id] = c
      } else {
        var i = this.form[id].indexOf(c)
        if(i > -1) {
          this.form[id].splice(i, 1)
        } else {
          this.form[id].push(c)
        }
      }
      this.questions.push({})
      this.questions.splice(this.questions.length - 1, 1)
    },
    async postAnswer(params) {
      params["job_id"] = this.$route.params.id
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/answers", 
          { answer: params }, 
          { headers: { Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json' }}
        ).catch(error => {
          console.log(error)
        })
    },
    async onSubmit(){
      for (let form_id in this.form) {
        this.answer.influencer_id = this.influencer.id
        this.answer.question_id = parseInt(form_id)
        const answer = this.form[`${form_id}`]
        if (!Array.isArray(answer)) {
          this.answer.answer = answer
          await this.postAnswer(this.answer)
        } else {
          answer.forEach(async(element) => {
            this.answer.answer = element
            await this.postAnswer(this.answer)
          })
        }
      }
      this.$router.push({ path: '/survey_thanks' })
    },
    getInfluencer() {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then((res) => {
          this.influencer = res.data
          if(this.influencer.first_name == null || this.influencer.email == null){
            this.$router.push({ path: '/influencer_form' })
          }
        }).catch(error => {
          console.log(error)
        })
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/jobs/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.job = res.data.job
        this.questions = res.data.opt_questions
      }).catch(error => {
        console.log(error)
      })
    this.getInfluencer()
  },
}
</script>

<style scoped lang="scss">
.job_application {
  max-width: 720px;
  margin: auto;
}


.alert-area{
  padding: 15px 0;
  max-width: 720px;
  margin: auto;
}
.float-left{
  float: left;
}
.title{
  font-weight: 600;
}
.border {
  border-bottom: 1px solid #E0E0E0;
}
.custom-control, .custom-control-inline{
  display: inherit;
  margin: .4rem auto;
}
input[type=checkbox] {
  display: none;
}
.customCheck 
.custom-control-input[type=checkbox],.custom-control-input[type=radio] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  height: 20px !important;
  width: 20px !important;
  vertical-align: -0.8rem !important;
  transition:all .15s ease-out 0s !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  margin: .4rem !important;
  outline: none !important;
  border-radius: 10% !important;
}
/* Checkbox */
.custom-control-input[type=checkbox]:before,.custom-control-input[type=checkbox]:after {
    position: absolute !important;
    content: "" !important;
    background: #fff !important;
    transition: all .2s ease-in-out !important;
}
.custom-control-input[type=checkbox]:before {
    left: 2px !important;
    top: 6px !important;
    width: 0 !important;
    height: 2px !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -o-transform: rotate(45deg) !important;
}
.custom-control-input[type=checkbox]:after {
    right: 9px !important;
    bottom: 3px !important;
    width: 2px !important;
    height: 0 !important;
    transform: rotate(40deg) !important;
    -webkit-transform: rotate(40deg) !important;
    -moz-transform: rotate(40deg) !important;
    -ms-transform: rotate(40deg) !important;
    -o-transform: rotate(40deg) !important;
    transition-delay: .2s !important;
}
.custom-control-input[type=checkbox]:checked:before {
    left: 1px !important;
    top: 10px !important;
    width: 6px !important;
    height: 2px !important;
}
.custom-control-input[type=checkbox]:checked:after {
    right: 5px !important;
    bottom: 1px !important;
    width: 2px !important;
    height: 14px !important;
}
.custom-control-input[type=checkbox]:indeterminate:before,.custom-control-input[type=checkbox]:indeterminate:after {
    width: 7px !important;
    height: 2px !important;
    transform: rotate(0) !important;
    -webkit-transform: rotate(0) !important;
    -moz-transform: rotate(0) !important;
    -ms-transform: rotate(0) !important;
    -o-transform: rotate(0) !important;
}
.custom-control-input[type=checkbox]:indeterminate:before {
    left: 1px !important;
    top: 7px !important;
}
.custom-control-input[type=checkbox]:indeterminate:after {
    right: 1px !important;
    bottom: 7px !important;
}
.custom-control-input[type=checkbox].blue,.custom-control-input[type=radio].blue {
    border: 2px solid #2196f3 !important;
}
.custom-control-input[type=checkbox].blue:checked,.custom-control-input[type=checkbox].blue:indeterminate,.custom-control-input[type=radio].blue:checked:before {
    background: #2196f3 !important;
}

.links{
  margin: 15px auto;
}

.title-header {
  background: #fff;
}

.logo_img {
  width: 18%;
}

</style>
