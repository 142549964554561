

<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header :nav="'お知らせ'"></simple-text-header>
    <b-container v-for="notify in notifications" v-bind:key="notify.notify.id" fluid="sm" class="border_line">
      <b-row>
        <b-col cols="2" class="px-0">
          <img v-if="notify.notify.n_type == 'job'" class="logo_img" :src="notify.job.images" alt="Kitten">
          <img v-else class="logo_img" src="../../assets/icons/notification-ouvo.png" alt="Kitten">
        </b-col>
        <b-col cols="10" class="text-area float-left pt-1 pr-2 pl-3">
          <div class="float-left job-title mb-0 mt-0">
            <router-link v-if="notify.notify.n_type == 'job'" :to="'/applicationLists/' + notify.applicate[0].id">{{ notify.notify.title }}</router-link>
            <p v-else>{{ notify.notify.title }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="notifications.length == 0" fluid="sm" class="job-container">
      <b-row class="not_found">
        <b-col cols="12">
          <p class="auto px-2 my-5 py-5">現在、お知らせはありません</p>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="notifications.length != 0">
      <b-row>
        <b-col cols="12" class="pt-4">
          <b-button class="ouvo-button mb-3" @click="backPage">戻る</b-button>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'

import axios from 'axios'
import moment from 'moment'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'ApplicationLists',
  components: {
    SimpleTextHeader
  },
  data() {
    return{
      authUser: '',
      idToken: localStorage.getItem('idToken'),
      notifications: [],
      jobFields: [
        { key: 'title', label: 'タイトル' },
        { key: 'applicate_state', label: '応募状況' },
        { key: 'post_url', label: '投稿URL' }
      ],
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/notifications", { 
        headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}, 
      })
      .then((res) => {
        this.notifications = res.data
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    backPage: function() {
      window.history.back();
    },
  },
}
</script>

<style scoped lang="scss">
.btn-wrapper{
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;
}
.gray-btn{
  margin: 0;
  font-weight: 600;
  font-size: 12px;
}
.job-container{    
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.shadow{
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 8px;
}
.text-area{
  padding-bottom: 20px;
  padding-top: 14px;
  background-color: #ffffff;
  border-radius: 8px;
}
.job-title{
  font-weight: 400;
  text-align: left;
  line-height: 1.1;
  margin-top: .7rem;
  margin-bottom: 5px;
  width: 100%;
  background-color: #ffffff;
  a {
    color: #4E4E4E;
    font-size: 11px;
  }

}
.job-content{
  background-color: #ffffff;
  padding: .3rem 0;
}
.current-tab{
  color: #72CDC7;
  border-bottom: 2px solid #72CDC7;
}
.thumbnail{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.btn-apply{
  float: right;
}

@media (min-width: 768px){
  .job-container{
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 768px){
  .job-container{
    max-width: 540px;
  }
}
.border_line {
  border-bottom: 1px solid #595A59;
}
.info-box {
  border: 2px solid #4E4E4E; 
  padding: 5px 0px;
  p {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }
}
.not_found {
  height: 100%;
  p {
    font-weight: 800;
    font-size: 18px;
    color: #CACACA;
  }
}
.big_issue {
  background-color: red;
  color: white;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
}
.logo_img {
  width: 100%;
  height: 100%;
}
</style>
