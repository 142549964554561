/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:99ca65f3-72d4-4478-a30e-861a50f24bed",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vAp85P3C2",
    "aws_user_pools_web_client_id": "6bploj4jevpvrj2m884hpc4r85",
    "oauth": {
        "domain": "linesamplingservice7e5b9c3f-7e5b9c3f-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        //"redirectSignIn": "http://localhost:8080/callback",
        //"redirectSignOut": "http://localhost:8080/",
        "redirectSignIn": "https://line.mereco.app/callback",
        "redirectSignOut": "https://line.mereco.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};
export default awsmobile;
