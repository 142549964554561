<template>
  <div class="transparent_app">
    <transparent-header></transparent-header>
    <div>
      <b-container fluid="sm" class="job-detail-container">
        <b-row>
          <b-col cols="12" class="px-0 pb-3">
              <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="480">
                <b-carousel-slide v-for="img in arraySplit(job.images)" :key="img" :img-src="img"></b-carousel-slide>
              </b-carousel>
          </b-col>
          <b-col cols="12" class="text-area float-left px-3">
            <b-row align-h="between" class="px-0">
                <p class="tag">{{ job.category }}</p>
            </b-row>
            <div class="job-title w-100 py-2">{{ job.title }}</div>
            <div class="job-abst-form text-left">
                <img class="half-icon" src="../assets/icons/32/ouvo_icon_insta.png"/> <span>{{ job.follower }}フォロワー以上</span>
                <img class="half-icon" src="../assets/icons/32/ouvo_icon_TwoPersons.png"/><span>{{ job.monitor_num }}人</span>
                <img class="half-icon" src="../assets/icons/32/ouvo_icon_user.png"/><span>{{ job.min_age }}~{{ job.max_age }}歳</span><br/>
                <img class="half-icon" src="../assets/icons/32/ouvo_icon_clock.png"/><span>募集期限: {{ formatJobDate(job.application_end_datetime) }}締切</span><br/>
                <img class="half-icon" src="../assets/icons/32/ouvo_icon_Calendar.png"/><span>投稿期限: {{ formatJobDate(job.end_post_at) }}</span>
            </div>
            <div class="py-3 pb-4">
              <span v-for="j in medias" :key="j" class="media_icon px-1 mx-0" :class="isActiveMediaIconClass(j, job)">
                 <span v-if="isActiveMediaIcon(j, job)" class="px-0 mx-0"><img class="half-icon" src="../assets/icons/32/ouvo_icon_Cbox.png"/>{{ getIconTitle(j) }}</span>
                 <span v-else class="px-0 mx-0"><img class="half-icon" src="../assets/icons/32/ouvo_icon_Cbox_gray.png"/>{{ getIconTitle(j) }}</span>
              </span>
            </div>

          </b-col>
          <b-col cols="12" class="title-header px-3">
            <h3>商品の説明</h3>
          </b-col>
          <b-col cols="12" class="job-section-body px-3">
            <p class="py-3 mb-3" >{{ job.description }}</p>

          </b-col>
          <hr/>

          <b-col cols="12" class="title-header px-3">
            <h3>依頼内容</h3>
          </b-col>
          <b-col cols="12" class="job-section-body px-0">
            <b-table stacked :items="request_details" :fields="request_fields"></b-table>
          </b-col>

          <b-col v-show="isIncludeMedia('post')" cols="12" class="title-header px-3 mt-0">
            <h3>Instagramフィード投稿の注意点</h3>
          </b-col>
          <b-col v-show="isIncludeMedia('post')" cols="12" class="job-section-body px-3">
            <b-row class="product-form px-0 pt-3 pb-2">
              <p class="caution"><b>【画像】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(job.instagram_caution_image)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-row class="product-form px-0 pb-3 pt-0">
              <p class="caution"><b>【文章】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(job.instagram_caution_caption)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-table stacked class="default-table mb-4" :items="caution_details" :fields="caution_fields">
                <template v-slot:cell(official_account)="">
                  <a class="table_link" :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>
          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="title-header px-3">
            <h3>Instagramストーリーズ投稿の注意点</h3>
          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="job-section-body px-3">
            <b-row class="product-form px-0 py-3">
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(job.instagram_cation_story)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-table stacked class="default-table mb-4" :items="story_caution_details" :fields="story_caution_fields">
                <template v-slot:cell(story_url)="data">
                  <a class="table_link"  :href="data.item.story_url">{{ data.item.story_url }}</a>
                </template>
                <template v-slot:cell(official_account)="">
                  <a class="table_link" :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>
          </b-col>
          <b-col cols="12" class="title-header px-3">
            <h3>注意事項</h3>
          </b-col>
          <b-col cols="12" class="job-section-body px-3">
            <b-row class="product-form px-0 py-3">
                <p class="caution">{{ job.caution }}</p>
            </b-row>
          </b-col>

          <b-col cols="12" class="text-center pb-3 mb-3 pt-5">
            <b-button v-if="isLogin && !isApplicate" class="ouvo-active-button"><router-link :to="{ name: 'Application' }">応募する</router-link></b-button>
            <b-button v-if="isLogin && isApplicate" class="ouvo-button">既にお申し込み済みです</b-button>
            <b-button v-if="!isLogin" class="ouvo-button"><router-link :to="{ name: 'Application' }">ログインして申し込む</router-link></b-button>
            <br/>
            <b-button class="ouvo-button ouvo-backpage-button mb-2 mt-3" @click="backPage">戻る</b-button>
          </b-col>

        </b-row>

      </b-container>
    </div>

  </div>
</template>

<script>
import TransparentHeader from '@/components/TransparentHeader.vue'

import axios from 'axios'
import moment from 'moment'

export default {
  name: 'JobDetails',
  components: {
    TransparentHeader
  },
  created() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/jobs/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.job = res.data.job
        this.org = res.data.org
        this.products = res.data.products
        var m = ""

        Array.from(new Set((this.job.media || '').split(","))).forEach(e => {
            if(e == "story") {
              m += " ・ Instagramストーリー投稿\n"
            }
            if(e == "post") {
              m += " ・ Instagram通常投稿"
            }
        })

        this.request_details = [
            {  
                media: m, 
                service: this.job.service, 
                post_at: this.formatJobDate(this.job.start_post_at) + "~" + this.formatJobDate(this.job.end_post_at)
            }
        ]
        this.caution_details = [
            {must_hashtags: this.job.must_hashtags, recommend_hashtags: this.job.recommend_hashtags, official_account: this.job.official_account}
        ]
        this.story_caution_details = [
            {story_url: this.job.story_url, official_account: this.job.official_account}
        ]

        if(this.isIncludeMedia('story')) {
          this.request_fields.push({key: 'instagram_story_caution', label: 'ストーリー投稿'})
        }
        if(this.isIncludeMedia('post')) {
          this.request_fields.push({key: 'instagram_caution', label: 'Instagram投稿'})
          this.request_details[0]["instagram_caution"] = this.job.instagram_post_num + "枚以上"
        }

      }).catch(error => {
        console.log(error)
      })

    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.influencer = res.data
        this.isLogin = true

        axios
          .get(process.env.VUE_APP_API_ENDPOINT + "/applicates", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
          .then((res) => {
             res.data.applicate.forEach(a => {
               if(a.job_id == this.job.uuid && a.influencer_id == this.influencer.id) {
                 this.isApplicate = true
               }
             })
          })
      }).catch(() => {
        this.isLogin = false
      })
  },
  data() {
    return{
      authUser: "",
      isLogin: false,
      idToken: localStorage.getItem('idToken'),
      medias: ["story", "post", "twitter"],
      slide: 0,
      sliding: null,
      titles: {
        "story": "Instagramストーリー",
        "post": "Instagramフィード",
        "twitter": "Twitter",
      },
      job: {},
      org: {},
      products: [],
      request_details: [],
      request_fields: [
          {key: 'media', label: '稼働'},
          {key: 'service', label: '商品・サービス'},
          {key: 'post_at', label: '投稿期間'},
      ],
      caution_details: [],
      caution_fields: [
          {key: 'must_hashtags', label: '必須ハッシュタグ'},
          {key: 'recommend_hashtags', label: '推奨ハッシュタグ'},
          {key: 'official_account', label: '公式アカウント'},
      ],
      story_caution_fields: [
          {key: 'story_url', label: 'URL'},
          {key: 'official_account', label: '公式アカウント'},
      ],
      agesDict: {
          "": "",
          "10-19": "10代",
          "20-29": "20代",
          "30-39": "30代",
          "40-49": "40代",
      },
      influencer: {},
      isApplicate: false,
      jobOptions: [
           { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' }
      ]
    }
  },
  methods: {
    isActiveMediaIcon(j, job) {
      var a = this.getMedias(job.media)
      if(a.indexOf(j) > -1) {
        return true
      } else {
        return false
      }
    },
    arraySplit(s) {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    },
    isIncludeMedia(s) {
      var st = this.arraySplit(this.job.media)
      if(st != undefined) {
        return (st.indexOf(s) > -1)? true : false
      }
    },
    isActiveMediaIconClass(j, job) {
      if(this.isActiveMediaIcon(j, job)) {
        return ""
      } else {
        return "active_media"
      }
    },
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    getMedias(m) {
      return Array.from(new Set((m || '').split(",")))
    },
    getIconTitle(j) {
      return this.titles[j]
    },
    backPage: function() {
      window.history.back();
    },
    formatJobDate(d) {
      var a = moment(d)
      if(a.isValid()) {
          return a.format("YYYY年MM月DD日")
      } else {
          return ""
      }
    },

  }
}
</script>

<style scoped lang="scss">
img.icon{  
  width: 100%;
  height: 17rem;
  object-fit: cover;
}
.job-detail{
  padding: 34px 0px;
}
p{
  text-align: left;
}
h6.descs-title{
  font-weight: 600;
  font-size: 1.3rem;
}
p.job-description{
  font-size: 14px;
}
p.descs-title{
  font-weight: 600;
  margin: 0;
}
p.descs-detail{
  padding-left: 15px;
}
.card{
  border: none;
  border-style: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 8px;
}
.card>img{
  width: 100%;
  height: 12rem;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
.card-body{
  border-radius: 8px;
}
button a{
  text-decoration: none;
  color: #fff;
}
.links{
  margin: 15px auto;
}

.tag {
  padding: 4px 8px;
  margin: 0px;
  border: 1px solid #8E8E8E;
  border-radius: 30px;
  font-size: 11px;
  color: #8E8E8E;
  line-height: 1;
  padding-top: 6px;
}
/deep/ .table {
  color: #575757;
  margin-bottom: 0px;
  th, td {
    padding: 0px;
    font-size: 12px;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      white-space: break-spaces;
    }
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #F0F0F0;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
/deep/ .table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 1px !important;
}
/deep/ .table.default-table {
  color: #575757;
  th, td {
    padding: 0px;
    font-size: 12px;
    border: 1px solid #333;
    border-bottom: none;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      border-left: 1px solid #333;
      white-space: pre;
      white-space: break-spaces;
    }
  }
  td:first-child {
    border-top-width: 1px !important;
  }
  td:last-child {
    border: 1px solid #333;
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #fff;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
.top_cover_img {
  border-radius: 0px !important;
}
.half-icon {
  width: 5%;
  margin-left: 5px;
  background-color: #F2F2F2;

}
.media_icon {
  background-color: white;
  font-size: 11px;
  font-weight: 800;
}
/deep/ .table_link {
  color: #8FC7ED;
}

</style>
