<template>
  <div class="login">
    <h1>Logged in</h1>
    <a href="#" @click="signIn('LINE')">LINEでログイン</a>

  </div>
</template>

<script>
import { Auth } from "aws-amplify"

export default {
  name: 'Login',
  methods: {
    signIn(provider) {
      Auth.federatedSignIn({ provider })
      //const res = Auth.federatedSignIn({ provider })
      //console.log(res)
    }
  }
}
</script>