<template>
  <div>
    <div>
      <b-container fluid="sm" class="job_application border_bottom">
        <b-row>
          <b-col cols="12" class="p-0"><p class="page_title border p-4 mb-0">下記の質問にお答えください。</p></b-col>
        </b-row>
        <b-form class="px-3">
          <b-form-group v-for="(question) in questions" :key="question.id" class="mb-1">
            <p class="title q_title px-0 py-2 mb-0 text-left pt-3 pb-0">{{ question.title }}</p>
            <div v-if="question.q_type == 'radio' || question.q_type == 'pull_down' || question.q_type == 'checkbox'">
              <b-row class="filter-content px-3 py-0">
                <div v-for="c in question.content.split(',')" :key="c" class=" bg-gray my-1 py-1" @click="onClickCategory(c, question.id, question.q_type)">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveTab(c, question.id)">{{ c }}</span>
                </div>
              </b-row>
            </div>

            <div v-if="question.q_type == 'confirmation'">
              <b-form-checkbox-group :required="requireType(question.require_type)">
                <b-form-checkbox
                  v-model="form[question.id]"
                > 
                  {{ question.content }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
            <hr>
          </b-form-group>
          <b-form-group class="mb-1">
            <p class="title q_title px-0 py-2 mb-0 text-left pt-3 pb-0">フォロワー数を教えてください</p>
            <div>
              <b-row class="filter-content px-3 py-0">
                <div class=" bg-gray my-1 py-1" @click="onClickFollower('1000')">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveFollower('1000')">~1,000人以下</span>
                </div>
                <div class=" bg-gray my-1 py-1" @click="onClickFollower('5000')">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveFollower('5000')">~5,000人以上</span>
                </div>
                <div class=" bg-gray my-1 py-1" @click="onClickFollower('10000')">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveFollower('10000')">~10,000人以上</span>
                </div>
                <div class=" bg-gray my-1 py-1" @click="onClickFollower('20000')">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveFollower('20000')">~20,000人以上</span>
                </div>
              </b-row>
            </div>
            <hr>
          </b-form-group>

          <b-form-group v-show="job.is_variation == true" class="mb-1">
            <p class="title q_title px-0 py-2 mb-0 text-left pt-3 pb-0">希望するカラーを選択してください</p>
            <div>
              <b-row class="filter-content px-3 py-0">
                <div class=" bg-gray my-1 py-1" v-for="s in colorList" :key="s" @click="onClickColor(s)">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveColor(s)">{{ s }}</span>
                </div>
              </b-row>
            </div>
            <hr>
          </b-form-group>

          <b-form-group v-show="job.is_size == true" class="mb-1">
            <p class="title q_title px-0 py-2 mb-0 text-left pt-3 pb-0">希望するサイズを選択してください</p>
            <div>
              <b-row class="filter-content px-3 py-0">
                <div class=" bg-gray my-1 py-1" v-for="s in sizeList" :key="s" @click="onClickSize(s)">
                  <span class="ouvo-tag px-4 mx-2 py-2" :class="isActiveSize(s)">{{ s }}</span>
                </div>
              </b-row>
            </div>
            <hr>
          </b-form-group>




          <b-col cols="12" class="title-header px-0">
            <b-row>
              <b-col cols="12" class="p-0"><p class="caution_text p-3 mb-0">お申し込み前に必ず下記ご確認ください</p></b-col>
            </b-row>
            <b-container fluid="sm" class="alert-area px-3 py-0">
              <b-row class="caution-form px-3 py-3 text-left">
                <p class="caution">{{ job.caution }}</p>
                <p class="caution">* 申し込みを行うと原則キャンセルを行うことはできません</p>
                <p class="caution">* 当選した場合、辞退をすることはできません</p>
              </b-row>
            </b-container>
            <b-form-checkbox v-model="confirmStates" class="pt-2 text-center"> 
              注意事項を確認し、同意します。
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button @click="onSubmit"  class="ouvo-active-button my-2 mt-5 ouvo-applicate-button">応募を確定する</b-button>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button @click="backPage" class="ouvo-button ouvo-backpage-button my-2 ">戻る</b-button>
          </b-col>
        </b-form>
      </b-container>
    </div>
    
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Application',
  components: {
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      formGroup: '',
      job: {},
      influencer: {},
      form: {},
      answer: {},
      applicates: {follower: "", color: "", size: ""},
      product: [],
      option: {},
      confirmStates: false,
      organization: {},
      sizeList: [],
      colorList: []
    }
  },
  methods: {
    getQuestionName(id) {
      return id.toString()
    },
    requireType(option){
      return (option == 'required') ? true : false
    },
    isActiveTab: function(c, id) {
      return (this.form[id] != undefined && this.form[id].indexOf(c) > -1)? "ouvo-tag-active" : ""
    },
    isActiveFollower: function(c) {
      return (this.applicates.follower == c)? "ouvo-tag-active" : ""
    },
    isActiveSize: function(c) {
      return (this.applicates.size == c)? "ouvo-tag-active" : ""
    },
    isActiveColor: function(c) {
      return (this.applicates.color == c)? "ouvo-tag-active" : ""
    },
    getOptions(content){
      this.options = content.split(', ')
      return this.options
    },
    backPage: function() {
      window.history.back();
    },
    onClickFollower: function(c) {
      this.applicates.follower = c
    },
    onClickSize: function(c) {
      this.applicates.size = c
    },
    onClickColor: function(c) {
      this.applicates.color = c
    },
    onClickCategory(c, id, t) {
      if(this.form[id] == undefined) {
        this.form[id] = []
      }
      if(t == "radio" || t =="pull_down") {
        this.form[id] = c
      } else {
        var i = this.form[id].indexOf(c)
        if(i > -1) {
          this.form[id].splice(i, 1)
        } else {
          this.form[id].push(c)
        }
      }
      this.questions.push({})
      this.questions.splice(this.questions.length - 1, 1)
    },
    async postAnswer(params) {
      params["job_id"] = this.$route.params.id
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/answers", 
          { answer: params }, 
          { headers: { Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json' }}
        ).catch(error => {
          console.log(error)
        })
    },
    async onSubmit(){
      if(this.confirmStates == false) {
        alert("注意事項し同意しますにチェックを入れてください")
      } else {
        for (let form_id in this.form) {
          this.answer.influencer_id = this.influencer.id
          this.answer.question_id = parseInt(form_id)
          const answer = this.form[`${form_id}`]
          if (!Array.isArray(answer)) {
            this.answer.answer = answer
            await this.postAnswer(this.answer)
          } else {
            answer.forEach(async(element) => {
              this.answer.answer = element
              await this.postAnswer(this.answer)
            })
          }
        }
        await this.postApplicate()
      }
    },
    async postApplicate() {
      this.applicates.influencer_id = this.influencer.id
      this.applicates.job_id = this.$route.params.id
      this.applicates.applicate_state = 'submitted'
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/applicates", 
          { applicate : this.applicates },
          { headers: { Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json' }}
        )
        .then(async() => {
          this.$router.push({ path: '/thankyou/' + this.organization.uuid })
        }).catch(error => {
          console.log(error)
        })
    },
    getInfluencer() {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then((res) => {
          this.influencer = res.data
          if(this.influencer.first_name == null || this.influencer.email == null){
            this.$notify({
              group: 'notify',
              title: '申込の前に情報を登録してください',
              text: '以下のフォームを全て入力してください',
              type: "warn",
            });
            this.$router.push({ path: '/influencer_form' })
          }
        }).catch(error => {
          console.log(error)
        })
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/jobs/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.job = res.data.job
        this.organization = res.data.org
        this.product = res.data.products[0]

        this.sizeList = this.product.size_list.split(",")
        this.colorList = this.product.color_list.split(",")
      }).catch(error => {
        console.log(error)
      })
    this.getInfluencer()
  },
}
</script>

<style scoped lang="scss">
.job_application {
  max-width: 720px;
  margin: auto;
}


.alert-area{
  padding: 15px 0;
  max-width: 720px;
  margin: auto;
}
.float-left{
  float: left;
}
.q_title{
  font-weight: 500;
}
.page_title {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
}
.title{
  font-weight: 400;
}
.caution_text {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}
.border {
  border-bottom: 1px solid #E0E0E0;
}
.custom-control, .custom-control-inline{
  display: inherit;
  margin: .4rem auto;
}
input[type=checkbox] {
  display: none;
}
.customCheck 
.custom-control-input[type=checkbox],.custom-control-input[type=radio] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  height: 20px !important;
  width: 20px !important;
  vertical-align: -0.8rem !important;
  transition:all .15s ease-out 0s !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  margin: .4rem !important;
  outline: none !important;
  border-radius: 10% !important;
}
/* Checkbox */
.custom-control-input[type=checkbox]:before,.custom-control-input[type=checkbox]:after {
    position: absolute !important;
    content: "" !important;
    background: #fff !important;
    transition: all .2s ease-in-out !important;
}
.custom-control-input[type=checkbox]:before {
    left: 2px !important;
    top: 6px !important;
    width: 0 !important;
    height: 2px !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -o-transform: rotate(45deg) !important;
}
.custom-control-input[type=checkbox]:after {
    right: 9px !important;
    bottom: 3px !important;
    width: 2px !important;
    height: 0 !important;
    transform: rotate(40deg) !important;
    -webkit-transform: rotate(40deg) !important;
    -moz-transform: rotate(40deg) !important;
    -ms-transform: rotate(40deg) !important;
    -o-transform: rotate(40deg) !important;
    transition-delay: .2s !important;
}
.custom-control-input[type=checkbox]:checked:before {
    left: 1px !important;
    top: 10px !important;
    width: 6px !important;
    height: 2px !important;
}
.custom-control-input[type=checkbox]:checked:after {
    right: 5px !important;
    bottom: 1px !important;
    width: 2px !important;
    height: 14px !important;
}
.custom-control-input[type=checkbox]:indeterminate:before,.custom-control-input[type=checkbox]:indeterminate:after {
    width: 7px !important;
    height: 2px !important;
    transform: rotate(0) !important;
    -webkit-transform: rotate(0) !important;
    -moz-transform: rotate(0) !important;
    -ms-transform: rotate(0) !important;
    -o-transform: rotate(0) !important;
}
.custom-control-input[type=checkbox]:indeterminate:before {
    left: 1px !important;
    top: 7px !important;
}
.custom-control-input[type=checkbox]:indeterminate:after {
    right: 1px !important;
    bottom: 7px !important;
}
.custom-control-input[type=checkbox].blue,.custom-control-input[type=radio].blue {
    border: 2px solid #2196f3 !important;
}
.custom-control-input[type=checkbox].blue:checked,.custom-control-input[type=checkbox].blue:indeterminate,.custom-control-input[type=radio].blue:checked:before {
    background: #2196f3 !important;
}

.links{
  margin: 15px auto;
}

.title-header {
  background: #fff;
}

.ouvo-applicate-button {
  padding-right: 28px;
  padding-left: 28px;
}


</style>
