<template>
  <div class="simple_app">
    <simple-header></simple-header>
    <div class="home-container">
      <div class="pt-3 ">
        <b-row>
          <b-col cols="3" class="mx-2 px-1">
            <img v-if="influencer.picture == '' || influencer.picture == null" class="user_icon pl-3" src="../assets/circle.png" alt="Kitten">
            <img v-else class="user_icon " :src="influencer.picture" alt="Kitten">
          </b-col>
          <b-col cols="6" class="px-0 pt-1 text-left">
            <h1 class="mypage_top_name mb-0">{{ influencer.first_name }} {{ influencer.last_name }}</h1>

            <a v-show="influencer.instagram_id != ''" :href="'https://www.instagram.com/' + influencer.instagram_id"><img class="w24px" src="../assets/icons/32/ouvo_icon_insta_black.png" alt="Kitten"></a>
            <a v-show="influencer.twitter_id != ''" :href="'https://twitter.com/' + influencer.twitter_id"><img class="w24px" src="../assets/icons/32/ouvo_icon_twitter_black.png" alt="Kitten"></a>
          </b-col>
          <b-col cols="2" class="px-0">
              <a href="/setting"><img class="w40px pt-2" src="../assets/icons/menu/ouvo_haguruma.png" alt="Kitten"></a>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="title-header px-3">
            <h3 class="mt-2 mx-0 mb-1">メニュー</h3>
          </b-col>
        </b-row>
        <b-row v-for="m in menus" :key="m.text" class="menu_row"  @click="jumpLink(m.link)">
          <b-col cols="2" class="px-0">
            <img class="menu_icon pl-1 m-2" :src="getImageUrl(m.icon)" alt="Kitten">
          </b-col>
          <b-col cols="8" class="px-0 menu">
            <p class="text-left menu_text">{{ m.text }}</p>
          </b-col>
          <b-col cols="2" class="px-2 menu text-right">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="title-header px-3 mt-0">
            <h3 class=" mx-0 mb-1 mt-2">その他</h3>
          </b-col>
        </b-row>
        <b-row v-for="h in helps" :key="h.text" class="menu_row" @click="jumpLink(h.link)">
          <b-col cols="2" class="px-0">
            <img class="menu_icon pl-1 m-2" :src="getImageUrl(h.icon)" alt="Kitten">
          </b-col>
          <b-col cols="8" class="px-0 menu">
            <p class="text-left menu_text">{{ h.text }}</p>
          </b-col>
          <b-col cols="2" class="px-2 menu text-right">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
            
          </b-col>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    SimpleHeader
  },
  data() {
    return {
      influencer: {},
      authUser: '',
      idToken: localStorage.getItem('idToken'),
      menus: [
          {text: "やることリスト", icon: 'ouvo_yarukoto.png', link: "/todo"},
          {text: "募集中の案件を探す", icon: 'ouvo_bosyucyu.png', link: "/jobs"},
          {text: "応募状況の確認", icon: 'ouvo_jyokyo.png', link: "/applicationLists"},
          {text: "いいね！した案件", icon: 'ouvo_hart.png', link: "/likes"},
          {text: "お気に入り企業", icon: 'ouvo_star.png', link: "/favorites"},
          {text: "完了した案件", icon: 'ouvo_kanryo.png', link: "/finished_job"},
      ],
      helps: [
          {text: "お知らせ", icon: "ouvo_oshirase.png", link: "/notifications"},
          {text: "お問い合わせ", icon: "ouvo_otoiawase.png", link: "/form"},
          {text: "ユーザー情報を設定", icon: "ouvo_haguruma.png", link: "/setting"},
      ]
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.influencer = res.data
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    getImageUrl: function(c) {
      return require('../assets/icons/menu/' + c)
    },
    jumpLink: function(l) {
      this.$router.push({path: l})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.w-30 {
  width: 30% !important;
}
.w-60 {
  width: 60% !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.red{
  color: #d48585;
  font-weight: 600;
}
.btn.btn-secondary{
  background-color: #00AAEE;
  border-color: #00AAEE;
  width: 14rem;
}
.btn a{
  color: #fff;
}
.title{
  font-weight: 600;
}
.job-container{
  padding: 10px 0;
  border-bottom: 1px solid #f3f2f4;
}
.text-area{
  padding: 0;
  padding-right: 15px;
}
.job-title{
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  line-height: 1.4;
  margin-bottom: 5px;
  width: 100%;
}
.icon{
  width: 100px;
  height: 100px;
}
.menu_icon {
  width: 65%;
}
.user_icon {
  width: 100%;
  border-radius: 50%;
}
.monitor{
  font-size: 11px;
  color: #888;
}
.menu {
  height: 100%;
}
.menu_text{
  vertical-align: middle;
  font-size: 14px;
  color: #4E4E4E;
  font-weight: 600;
  line-height: 85px;
  margin-bottom: 0px;
}
.title-header {
  background-color: #E5E5E5;
  h3 {
    background-color: #E5E5E5;
    color: #4D4D4D;
  }
}
.menu_row {
  border-bottom: 1px solid #595A59;
}
.mypage_top_name {
  font-size: 20px;
  font-weight: 800;
  text-align: left;
}
.w24px {
  width: 24px;
}
.w32px {
  width: 32px;
}
.w40px {
  width: 40px;
}


@media (max-width: 768px){
  .menu_text {
    line-height: 55px;
  }
}

</style>
