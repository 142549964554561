

<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header :nav="'ユーザー情報を設定'"></simple-text-header>
    <div class="home-container">
      <div class="pt-0">
        <b-row>
          <b-col cols="12" class="title-header pl-3 pr-0">
              <h3 class="mt-2 ml-0 mb-1">{{ label() }}</h3>
          </b-col>
        </b-row>
        <div v-if="mode == 'name'">
          <b-row class="profile">
            <b-col cols="3" class="px-0">
              <h2 class="label px-2 ml-1">お名前</h2>
            </b-col>
            <b-col cols="4" class="menu pr-1">
                <b-form-input :state="validateState('first_name')" class="ouvo-input" v-model="influencer.first_name" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) 田中</p>
            </b-col>
            <b-col cols="5" class="menu pl-1">
                <b-form-input :state="validateState('last_name')" class="ouvo-input" v-model="influencer.last_name" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) 優子</p>
            </b-col>
          </b-row>
          <b-row class="profile">
            <b-col cols="3" class="px-0">
              <h2 class="label px-2 ml-1">フリガナ</h2>
            </b-col>
            <b-col cols="4" class="menu pr-1">
                <b-form-input :state="validateState('first_kana_name')" class="ouvo-input" v-model="influencer.first_kana_name" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) タナカ</p>
            </b-col>
            <b-col cols="5" class="menu pl-1">
                <b-form-input :state="validateState('last_kana_name')" class="ouvo-input" v-model="influencer.last_kana_name" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) ユウコ</p>
            </b-col>
          </b-row>

        </div>


        <div v-if="mode == 'email'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">メールアドレス</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input :state="validateState('email')" class="ouvo-input" v-model="influencer.email" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) info@ouvo.co.jp</p>
            </b-col>
          </b-row>
        </div>


        <div v-if="mode == 'phone'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">電話番号</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input  :state="validateState('phone_number')" class="ouvo-input" v-model="influencer.phone_number" type="tel" required />
                <p class="input-description mb-2 mt-1 text-left">例) 080-1234-1234</p>
            </b-col>
          </b-row>
        </div>
        <div v-if="mode == 'address'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">郵便番号</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input :state="validateState('zip')" class="ouvo-input" v-model="influencer.zip" @change="updateZip"  type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) 1234567</p>
            </b-col>
          </b-row>
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">住所</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input :state="validateState('address1')" class="ouvo-input" v-model="influencer.address1" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) 東京都港区芝公園1-2-3</p>
            </b-col>
          </b-row>
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">建物名</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input class="ouvo-input" v-model="influencer.address2" type="text" required />
                <p class="input-description mb-2 mt-1 text-left">例) シバマンション101</p>
            </b-col>
          </b-row>
        </div>

        <div v-if="mode == 'gender'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">性別</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-select :state="validateState('gender')" class="ouvo-input" v-model="influencer.gender" :options="gender"></b-form-select>
            </b-col>
          </b-row>
        </div>

        <div v-if="mode == 'occupation'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">職業</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-select class="ouvo-input" v-model="influencer.gender" :options="gender"></b-form-select>
            </b-col>
          </b-row>
        </div>

        <div v-if="mode == 'instagram'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">Instagram</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input :state="validateState('instagram_id')"  class="ouvo-input" v-model="influencer.instagram_id" type="text" required />
            </b-col>
          </b-row>
        </div>

        <div v-if="mode == 'twitter'">
          <b-row class="profile">
            <b-col cols="4" class="px-0">
              <h2 class="label px-4 ml-1">Twitter</h2>
            </b-col>
            <b-col cols="8" class="menu">
                <b-form-input :state="validateState('twitter_id')" class="ouvo-input" v-model="influencer.twitter_id" type="text" required />
            </b-col>
          </b-row>
        </div>




        <b-col cols="12" class="text-center pt-4">
          <b-button @click="onSubmit" class="ouvo-button my-2">登録する</b-button>
        </b-col>

      </div>
    </div>

  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'
let YubinBango = require('yubinbango-core2')
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import axios from 'axios'

export default {
  name: 'Home',
  mixins: [validationMixin],
  components: {
    SimpleTextHeader
  },
  validations: {
    influencer: {
      first_name: { required },
      first_kana_name: { required },
      last_name: { required },
      last_kana_name: { required },
      phone_number: { required },
      email: { required },
      zip: { required },
      address1: { required },
      gender: { required },
      instagram_id: { required },
      twitter_id: { required },
    }
  },
  data() {
    return {
      influencer: {
        phone_number: ""
      },
      authUser: '',
      gender: ['男性', '女性', 'その他'],
      idToken: localStorage.getItem('idToken'),
      mode: "",
    }
  },
  mounted() {
    var m = this.$route.query.m
    this.mode = m
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.influencer = res.data
    console.log(this.influencer)
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.influencer[name];
      return $dirty ? !$error : null;
    },
    label: function() {
      if(this.mode == "instagram" || this.mode == "twitter") {
        return "SNS情報"
      } else {
        return "基本情報"
      }
    },
    updateZip: function() {
      new YubinBango.Core(this.influencer.zip, (addr) => {
          this.influencer.address1 = addr['region'] + addr['locality'] + addr['street']

      })
    },
    onSubmit: function() {
      this.$v.influencer.$touch();
      if (this.$v.influencer.$anyError) {
        return;
      }

      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/influencer", { influencer: this.influencer }, 
                { headers: { Authorization: `Bearer ${this.idToken}`, 
                             'Content-Type': 'application/json' }
        })
        .then((res) => {
          this.$router.push({ name: 'Setting' })
          console.log(res.data)
        }).catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile {
    padding: 12px 0px;
    border-bottom: 1px solid #A6A6A6;
    .label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: left;
        padding-top: 5px;
        padding-right: 5px !important;
    }
    .menu_text {
        font-size: 14px;
        color: #CACACA;
        margin-bottom: 0px;
    }
}
.title-header {
  margin-top: 0px !important;
}
</style>
