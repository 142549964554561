<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header></simple-text-header>
    <div class="text-left px-2 home-container">
      <b-row>
        <b-col cols="12" class="title-header px-3 mb-3">
          <h3>基本情報</h3>
         </b-col>
        <b-col cols="6" class="py-2">
          <label class="form-label">お名前<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input  :state="validateState('first_name')" class="ouvo-input" v-model="form.first_name" type="text" required />
          <p class="input-description mb-2 mt-1">例) 田中</p>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input :state="validateState('last_name')" class="ouvo-input" v-model="form.last_name" type="text" />
          <p class="input-description mb-2 mt-1">例) 優子</p>
        </b-col>
          
        <b-col cols="6" class="py-2">
          <label class="form-label">フリガナ<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input :state="validateState('first_kana_name')" class="ouvo-input" v-model="form.first_kana_name" type="text" required />
          <p class="input-description mb-2 mt-1">例) タナカ</p>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-form-input :state="validateState('last_kana_name')" class="ouvo-input" v-model="form.last_kana_name" type="text" />
          <p class="input-description mb-2 mt-1">例) ユウコ</p>
        </b-col>
          
        <b-col cols="6" class="py-2">
          <label class="form-label">メールアドレス<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input :state="validateState('email')" class="ouvo-input" v-model="form.email" type="text" required />
          <p class="input-description mb-2 mt-1">例) info@ouvo.co.jp</p>
        </b-col>

        <b-col cols="6" class="py-2">
          <label class="form-label">電話番号<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input :state="validateState('phone_number')" type="tel" class="ouvo-input" v-model="form.phone_number" required pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"/>
          <p class="input-description mb-2 mt-1">例) 080-1234-1234</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">郵便番号<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input :state="validateState('zip')" class="ouvo-input" @change="updateZip" v-model="form.zip" type="number" required />
          <p class="input-description mb-2 mt-1">例) 1234567</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">住所<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input :state="validateState('address1')"  class="ouvo-input" v-model="form.address1" type="text" required />
          <p class="input-description mb-2 mt-1">例) 東京都港区芝公園1-2-3</p>
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">建物名</label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.address2" type="text" required />
          <p class="input-description mb-2 mt-1">例) シバマンション101</p>
        </b-col>
  
        <b-col cols="6" class="py-2">
          <label class="form-label">生年月日<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form inline>
            <b-form-select class="mb-2 mr-sm-2 mb-sm-0 ouvo-input" v-model="year" required :options="years"/>年
            <b-form-select class="mb-2 mr-sm-1 ml-sm-2 mb-sm-0 ouvo-input" v-model="month" required :options="months"/>月
            <b-form-select class="mb-2 mr-sm-1 ml-sm-2 mb-sm-0 ouvo-input" v-model="day" required :options="days"/>日
          </b-form>

        </b-col>
  
        <b-col cols="6" class="py-2">
          <label class="form-label">性別<span class="required-label py-0 px-1 mx-1">必須</span></label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-select :state="validateState('gender')" class="ouvo-input" v-model="form.gender" :options="gender"></b-form-select>
        </b-col>

        <b-col cols="12" class="title-header px-3 mb-3">
          <h3>SNS情報(Instagram 又は Twitterのアカウントを連携)</h3>
         </b-col>

        <b-col cols="6" class="py-2">
          <label class="form-label">Instagram</label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.instagram_id" type="text" placeholder="ouvo" />
        </b-col>
 
        <b-col cols="6" class="py-2">
          <label class="form-label">Twitter</label>
        </b-col>
        <b-col cols="6" class="px-1">
          <b-form-input class="ouvo-input" v-model="form.twitter_id" type="text" placeholder="ouvo" />
        </b-col>
        <b-col cols="12" class="text-center">
          <b-button @click="onSubmit" class="ouvo-button my-2">登録する</b-button>
        </b-col>
      </b-row>
    </div>

    <amplify-sign-out button-text="サインアウト"></amplify-sign-out>
  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'
let YubinBango = require('yubinbango-core2')
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import axios from 'axios'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'InfluencerForm',
  mixins: [validationMixin],
  components: {
    SimpleTextHeader
  },
  data() {
    return{
      authUser: "",
      userId: "",
      authState: "",
      form: {},
      gender: ['男性', '女性', 'その他'],
      show: true,
      idToken: localStorage.getItem('idToken'),
      year: "1990",
      month: "6",
      day: "15",
      years: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    }
  },
  validations: {
    form: {
      first_name: { required },
      first_kana_name: { required },
      last_name: { required },
      last_kana_name: { required },
      phone_number: { required },
      email: { required },
      birthday: { required },
      zip: { required },
      address1: { required },
      gender: { required },
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: { Authorization: `Bearer ${this.idToken}`}})
      .then((res) => {
        this.form = res.data
      }).catch(error => {
        console.log(error)
      })

    for (let i = 1950; i < 2016; i++) {
      this.years.push(i)
    }
  },
  methods: {
    updateZip: function() {
      new YubinBango.Core(this.form.zip, (addr) => {
          this.form.address1 = addr['region'] + addr['locality'] + addr['street']

      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(this.form.instagram_id == "" && this.form.twitter_id == "") {
        alert("Instagram もしくは Twitterのアカウントを連携してください")
      }
      this.form.birhday = this.year.toString() + "/" + this.month.toString() + "/" + this.day.toString()

      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/influencers", { influencer: this.form }, 
                { headers: { Authorization: `Bearer ${this.idToken}`, 
                             'Content-Type': 'application/json' }
        })
        .then((res) => {
          console.log(res.data)
          this.$notify({
            group: 'notify',
            title: '登録が完了しました。',
            text: '案件に応募してみましょう',
          });
          this.$router.push({ path: '/jobs'})
        }).catch(error => {
          console.log(error)
        })
    },
  }
}
</script>

<style scoped >
.required-label {
  background: #033E68;
  color: white;
  font-size: 10px;
}
.form-label {
  font-size: 12px;
}
.input-description {
  color: #CACACA;
  font-size: 10px;
  font-weight: 500;
}
.title-header {
  margin-top: 0px !important;
}
</style>

