<template>
  <div class="callback">
    <h1>Loading</h1>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Callbacke',
  data() {
    return {
    }
  },
  mounted() {
    this.$Amplify.Auth.currentAuthenticatedUser()
      .then((data) => {
        const token = data.signInUserSession.idToken.jwtToken
        localStorage.idToken = data.signInUserSession.idToken.jwtToken
        axios
          .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'}})
          .then(() => {
            this.$router.push({ path: '/' })
          }).catch(err => {
            console.log(err)
          })
    })
  }
}
</script>
