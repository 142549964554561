import { render, staticRenderFns } from "./SimpleTextHeader.vue?vue&type=template&id=12fbb422&scoped=true&"
import script from "./SimpleTextHeader.vue?vue&type=script&lang=js&"
export * from "./SimpleTextHeader.vue?vue&type=script&lang=js&"
import style0 from "./SimpleTextHeader.vue?vue&type=style&index=0&id=12fbb422&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fbb422",
  null
  
)

export default component.exports