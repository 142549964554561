import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Amplify読み込み
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue'
import aws_exports from './aws_exports.js'
import '@aws-amplify/ui-vue';
import Notifications from 'vue-notification'

// VueBootstrap読み込み
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/style.scss'
//

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)

Amplify.configure(aws_exports)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
