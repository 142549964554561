
<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header :nav="'ユーザー情報を設定'"></simple-text-header>
    <div class="home-container">
      <div class="pt-0">
        <b-row>
          <b-col cols="12" class="title-header px-1">
            <h3 class="mt-2 mx-1 mb-1">基本情報</h3>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('name')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">お名前</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">{{ influencer.first_name }}  {{ influencer.last_name }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('email')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1 pr-0">メールアドレス</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">{{ influencer.email }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('phone')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">電話番号</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
            <p class="text-left menu_text">{{ influencer.phone_number }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('address')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">住所</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">{{ influencer.zip }}<br/>{{ influencer.address1 }}<br/>{{ influencer.address2 }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('gender')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">性別</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">{{ influencer.gender }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="title-header px-3 mt-0">
            <h3 class=" mx-3 mb-1 mt-2">SNS情報</h3>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('instagram')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">Instagram</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">@{{ influencer.instagram_id }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>
        <b-row class="profile"  @click="jumpLink('twitter')">
          <b-col cols="4" class="px-0">
            <h2 class="label px-4 ml-1">Twitter</h2>
          </b-col>
          <b-col cols="6" class="px-0 menu">
              <p class="text-left menu_text">{{ influencer.twitter_id }}</p>
          </b-col>
          <b-col cols="2" class="px-0 menu">
            <p class="menu_text"><b-icon icon="chevron-right" class="menu_text mr-0"></b-icon></p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="px-0 menu text-center">
            <amplify-sign-out button-text="サインアウト"></amplify-sign-out>
          </b-col>
        </b-row>

      </div>
    </div>

  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    SimpleTextHeader
  },
  data() {
    return {
      influencer: {},
      authUser: '',
      idToken: localStorage.getItem('idToken'),
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/influencer", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.influencer = res.data
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    jumpLink: function(m) {
      this.$router.push({ name: 'ProfileSetting', query: { m: m }})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile {
    padding: 12px 0px;
    border-bottom: 1px solid #A6A6A6;
    .label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: left;
        padding-top: 5px;
        padding-right: 5px !important;
    }
    .menu_text {
        font-size: 14px;
        color: #CACACA;
        margin-bottom: 0px;
    }
}
.title-header {
    margin-top: 0px;
}
</style>
