<template>
  <div id="app">
    <notifications group="notify" position="top center" class="mt-3"/>
    <router-view/>
  </div>
</template>

<script>
  import { components } from 'aws-amplify-vue'

  export default {
    name: 'app', 
    components: {
      ...components
    },
    data() {
      return {
        p: ""
      }
    },
    methods: {
      getRootId() {
        var i = "simple_app"
        if(this.p.indexOf("/jobs/") > -1 && this.p.length > 6 && this.p.indexOf("application") > -1) {
          i = "applicate"
        } 
        if(this.p.indexOf("/jobs/") > -1 && this.p.length > 6 && this.p.indexOf("application") == -1) {
          i = "transparent_app"
        } 
        if(this.p == "/thankyou" || this.p.indexOf("applicationLists") > -1) {
          i = "transparent_app"
        } 
        if(this.p == "/applicationLists") {
          i = "simple_app"
        }

        return i
      }
    },
    mounted() {
      this.p = this.$route.path
    }
  }
</script>

<style>
.red{
  color: #d48585;
  font-weight: 600;
}
.btn.btn-secondary{
  background-color: #00AAEE;
  border-color: #00AAEE;
}
.btn a{
  color: #fff;
}
button:hover{
  background-color: #6c757d;
  border-color: #6c757d;
}
.blue{
  background-color: #00AAEE;
  border-radius: 8px;
}
.links{
  margin: 40px auto;
}
li {
  list-style: none;
}
</style>
