<template>
  <div class="ApplicationLists simple_app">
    <simple-text-header :nav="'応募状況の確認'"></simple-text-header>
    <b-container class="pt-3 mt-1">
      <b-row>
        <b-col class="btn-wrapper my-0" v-for="(name, state) in applicateStates" :key="state" :class="getCurrentTabClass(state)">
          <p  @click="switchTab(state)"
              class="gray-btn mb-2"
          >{{ name }}</p>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-for="job in filteredJobs" v-bind:key="job.uuid" fluid="sm" class="border_line">
      <b-row v-if="currentFilterType == 'submitted'">
        <b-col cols="2" class="px-0 main_image">
          <b-img class="thumbnail" :src="arraySplit(job.images)[0]"></b-img>
        </b-col>
        <b-col cols="7" class="text-area float-left pt-1 pr-0 pl-3">
            <div class="float-left job-title mb-0 mt-0"><router-link :to="'/applicationLists/' + job.id">{{ job.title }}</router-link></div>
        </b-col>
        <b-col cols="3" class="px-0 ">
          <div class="info-box my-2 mx-3" :class="limitCss(calcUntilDays(job))">
              <p class="mb-0">発表まで<br/>あと{{ calcUntilDays(job) }}日</p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentFilterType == 'applied'">
        <b-col cols="2" class="px-0">
          <b-img class="thumbnail" :src="arraySplit(job.images)[0]"></b-img>
        </b-col>
        <b-col cols="7" class="text-area float-left pt-1 pr-0 pl-3">
          <div class="float-left job-title mb-0 mt-0"><router-link :to="'/applicationLists/' + job.id">{{ job.title }}</router-link></div>
        </b-col>
        <b-col cols="3" class="px-0 ">
          <div class="info-box my-2 mx-3 py-2" :class="stateClass(job)">
              <p class="mb-0">{{ stateText(job) }}</p>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="currentFilterType == 'rejeceted'">
        <b-col cols="2" class="px-0">
          <b-img class="thumbnail" :src="arraySplit(job.images)[0]"></b-img>
        </b-col>
        <b-col cols="10" class="text-area float-left pt-1 pr-0 pl-3">
          <div class="float-left job-title mb-0 mt-0"><router-link :to="'/applicationLists/' + job.id">{{ job.title }}</router-link></div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-if="filteredJobs.length == 0" fluid="sm" class="job-container">
      <b-row class="not_found">
        <b-col cols="12">
          <p class="auto m-5 p-5">該当案件 なし</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SimpleTextHeader from '@/components/SimpleTextHeader.vue'

import axios from 'axios'
import moment from 'moment'
//import { onAuthUIStateChange } from '@aws-amplify/ui-components'

export default {
  name: 'ApplicationLists',
  components: {
    SimpleTextHeader
  },
  data() {
    return{
      authUser: '',
      idToken: localStorage.getItem('idToken'),
      filteredJobs: [],
      currentFilterType: '審査中',
      jobFields: [
        { key: 'title', label: 'タイトル' },
        { key: 'applicate_state', label: '応募状況' },
        { key: 'post_url', label: '投稿URL' }
      ],
      applicateStates: {
        'submitted': '抽選待ち',
        'applied': '当選',
        'rejeceted': '落選'
      }
    }
  },
  mounted() {
    this.switchTab('submitted')
  },
  methods: {
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    arraySplit(s) {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    },
    limitCss(d) {
      return (d < 3)? "ouvo-red ouvo-border-red":""
    },
    stateClass(applicate) {
      var app_state = applicate.applicate_state
      var manage_state = applicate.manage_state  || "first"
      var post_state = applicate.post_state || "not_posted"
      if(app_state == "applied" && manage_state == "first") {
        return ""
      } else if(app_state == "applied" && manage_state == "in_transit") {
        return ""
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "not_posted") {
        return ""
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "checking") {
        return "ouvo-border-darkgray darkgray-back"
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "re-post") {
        return "ouvo-border-red red-back white-text"
      }
    },
    stateText(applicate) {
      var app_state = applicate.applicate_state
      var manage_state = applicate.manage_state || "first"
      var post_state = applicate.post_state || "not_posted" 
      if(app_state == "applied" && manage_state == "first") {
        return "出荷準備中"
      } else if(app_state == "applied" && manage_state == "in_transit") {
        return "配送中"
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "not_posted") {
        return "配送済み"
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "checking") {
        return "提出済み"
      } else if(app_state == "applied" && manage_state == "delivered" && post_state == "re-post") {
        return "再投稿"
      }
    },
    switchTab(tab) {
      this.currentFilterType = tab
      this.currentTabContenet(tab)
      this.getCurrentTabClass(tab)
    },
    getCurrentTabClass(tab) {
      return (tab == this.currentFilterType)? 'current-tab' : ''
    },
    calcUntilDays(job) {
      var now = moment()
      return moment(job.selection_datetime).diff(now, 'days');
    },
    currentTabContenet(tab) {
      console.log(tab)
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/applicates", { 
          headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}, 
          params: { state: tab } 
        })
        .then((res) => {
          this.filteredJobs = res.data.applicate
        }).catch(error => {
          console.log(error)
        })
    }
  },
}
</script>

<style scoped lang="scss">
.btn-wrapper{
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;
}
.gray-btn{
  margin: 0;
  font-weight: 600;
  font-size: 12px;
}
.job-container{    
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.shadow{
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
  border-radius: 8px;
}
.text-area{
  padding-bottom: 20px;
  padding-top: 14px;
  background-color: #ffffff;
  border-radius: 8px;
}
.job-title{
  font-weight: 400;
  text-align: left;
  line-height: 1.1;
  margin-top: .7rem;
  margin-bottom: 5px;
  width: 100%;
  background-color: #ffffff;
  a {
    color: #4E4E4E;
    font-size: 11px;
  }

}
.job-content{
  background-color: #ffffff;
  padding: .3rem 0;
}
.current-tab{
  color: #72CDC7;
  border-bottom: 2px solid #72CDC7;
}
.thumbnail{
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.btn-apply{
  float: right;
}
.links{
  margin: 40px auto;
}


@media (min-width: 768px){
  .job-container{
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 768px){
  .job-container{
    max-width: 540px;
  }
}
.border_line {
  border-bottom: 1px solid #595A59;
}
.info-box {
  border: 2px solid #4E4E4E; 
  padding: 5px 0px;
  p {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }
}
.not_found {
  height: 100%;
  p {
    font-weight: 800;
    font-size: 20px;
  }
}



</style>
