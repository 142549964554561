
<template>
  <div class="thanks">
    <b-container fluid="sm" class="job_application border_bottom">
        <b-row class="text-center">
            <b-col cols="12" class="p-0"><p class="thanks_p pt-5 mb-0 mt-2">アンケートご協力<br/>ありがとうございました</p></b-col>
            <b-col cols="12" class="p-0">
              <img class="py-4 logo_img" src="../assets/icons/256/ouvo_icon_list.png" alt="Kitten">
            </b-col>
            <p class="small_caution">案件の状況はマイページから確認できます</p>
        </b-row>
        <b-col cols="12" class=" text-center py-3">
          <b-button href="/"  class="ouvo-button my-2">マイページ</b-button>
        </b-col>
        <p class="recommend_caption pt-4">こちらもおすすめ</p>
    </b-container>
  </div>
</template>

<script>
//import axios from 'axios'

export default {
  name: 'Application',
  mounted() {
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken')
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.logo_img {
  width: 36%;
}
.banner_img {
  width: 70%;
}
.hoshi_img {
  width: 20%;
  background: #9BDDDB;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.small_caution {
  width: 100%;
  font-size: 13px;
}
.btn.btn-secondary.btn-gray {
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  font-size: 14px;
  padding: 12px 20px;
  a {
    color: #373E45;
    font-weight: 500;
  }
}
.thanks_p{
  color: #7FCAC0;
  font-size: 20px;
  font-weight: 700;
}

.bg-blue {
  background: #9BDDDB;
}
.section-title {
  background: #9BDDDB;
  color: #525252;
  font-size: 14px;
  font-weight: 600;
}
</style>
