<template>
  <div class="transparent_app">
    <transparent-header></transparent-header>
    <div>
      <b-container fluid="sm" class="job-detail-container">
        <b-row>
          <b-col cols="12" class="px-0">
            <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="480">
              <b-carousel-slide v-for="img in arraySplit(applicate.images)" :key="img" :img-src="img"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="12" class="text-area float-left px-3">
            <div class="job-title w-100 py-1">{{ applicate.title }}</div>
            <hr class="my-1"/>

            <b-row class="text-center" v-show="app_state == 'submitted'">
                <b-col cols="12" class="p-0"><p class="applicate_caution pt-2 mb-0 mt-2">＜結果発表まであと{{ calcUntilDays(applicate) }}日＞</p></b-col>
                <p class="applicate_state_text my-2">抽選結果待ち</p>
                <p class="small_caution">{{ formatData(applicate.selection_datetime) }}に結果をお知らせします。<br/>いましばらくお待ちください</p>
                <b-col cols="12" class="p-0" v-if="opt_questions.length > 0">
                  <img class="pt-3 banner_img" src="../../assets/ouvo_anketo.png" alt="Kitten">
                  <b-button class="btn-gray mb-3 mt-2"><router-link :to="{ path: '/survey/' + applicate.job_id }">アンケートに答える ></router-link></b-button>
                </b-col>
            </b-row>
            <b-row class="text-center" v-show="app_state == 'applied' && post_state == 're-post'">
                <b-col cols="12" class="p-0">
                  <p class="applicate_repost_text ouvo-color mt-3 mb-0">投稿内容を確認しました</p>
                  <p class="repost_small_caution mb-3 mt-0 ouvo-red">再投稿をお願いします</p>
                  <b-button v-b-modal="'repost_caution'" class="ouvo-reject-square-button my-2 mt-2 mb-3">企業からメッセージを確認</b-button>

                </b-col>
            </b-row>
            <b-row class="text-center" v-show="app_state == 'applied'">
                <p class="applied_text ouvo-color my-4">{{ appliedStateText() }}</p>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈応募状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="applicateIconPath()" alt="Kitten">
                  <p class="text-center" :class="applicateClass()"><b>当選</b></p>
                </b-col>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈配送状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="manageIconPath()" alt="Kitten">
                  <p class="text-center" :class="manageClass()"><b>{{ manageStateText() }}</b></p>
                </b-col>
                <b-col cols="4" class="p-0">
                  <p class="app_sub_text mb-0">〈投稿状況〉</p>
                  <img class="pt-2 mb-1 app_state_img" :src="postIconPath()" alt="Kitten">
                  <p class="text-center" :class="postClass()"><b>{{ postStateText() }}</b></p>
                </b-col>
                <b-col cols="12" class="p-2 text-center" v-show="(post_state == 'not_posted' && manage_state == 'delivered') || post_state == 're-post'">
                    <p class="text-center mb-0 mt-2"><small>投稿が完了したら</small></p>
                    <b-button class="ouvo-active-button my-2 mt-2 mb-3"><router-link :to="{ path: '/applicationLists/' + this.$route.params.id + '/submitPost' }">投稿を報告</router-link></b-button>
                </b-col>
            </b-row>
            <b-row class="text-center" v-show="app_state == 'rejected'">
                <p class="applicate_state_text my-3">落選</p>
                <p class="small_caution">この度はご応募ありがとうございました。<br/>残念ながら今回は落選となりました。<br/>またのご応募をお待ちしてます。</p>

                <b-row class="company_section gray-back mt-3">
                  <b-col cols="12" class="pt-4 px-3 gray-back pt-2">
                    <h2 class="section-title gray-back">企業をお気に入りに登録しお知らせ通知を受け取ろう</h2>
                    <img class="pt-0 hoshi_img gray-back" src="../../assets/ouvo_hoshi.png" alt="Kitten">
                  </b-col>
                  <b-col cols="2" class="py-2 px-3 text-left gray-back">
                    <img class="pt-0 hoshi_img gray-back" src="../../assets/ouvo_hoshi.png" alt="Kitten">
                  </b-col>
                  <b-col cols="10" class="py-2 px-3 text-left gray-back">
                      <h2 class="company_name mb-0 gray-back">{{ org.name }}</h2>
                    <a class=" gray-back" href="https://www.cross-ring.com/"><small class=" gray-back">https://www.cross-ring.com/</small></a>
                    <p class="company_caption gray-back">老舗の箸作り専門店です。インフルエンサーマーケティングを請け負っています。</p>
                  </b-col>
                </b-row>
                <p class="recommend_caption pt-2 m-3">こちらもおすすめ</p>
            </b-row>
            <b-row class="text-center" v-show="app_state == 'completed'">
                <p class="applicate_state_text ouvo-color my-3">投稿確認完了</p>
                <p class="small_caution m-3">投稿内容を確認しました。<br/>この度はご応募ありがとうございました。<br/>本件は完了となります。<br/>またの応募をお待ちしております。</p>
            </b-row>


          </b-col>
          <b-col cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('description')" v-if="isShowState()">
            <h3>商品の説明</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col cols="12" class="job-section-body px-3" v-if="openSection['description']">
            <p class="py-3 mb-3" >{{ applicate.description }}</p>

            <p class="text-red">※お届けするのは、上記のうち１つです。お選びいただけません。</p>
            <div class="job-abst-form">
                <img class="half-icon" src="../../assets/icons/32/ouvo_icon_insta.png"/> <span>{{ applicate.follower }}フォロワー以上</span>
                <img class="half-icon" src="../../assets/icons/32/ouvo_icon_TwoPersons.png"/><span>{{ applicate.monitor_num }}人</span>
                <img class="half-icon" src="../../assets/icons/32/ouvo_icon_user.png"/><span>{{ applicate.ages }}歳</span><br/>
                <img class="half-icon" src="../../assets/icons/32/ouvo_icon_clock.png"/><span>募集期限: {{ formatJobDate(applicate.application_end_datetime) }}締切</span>
                <img class="half-icon" src="../../assets/icons/32/ouvo_icon_Calendar.png"/><span>投稿期限: {{ formatJobDate(applicate.end_post_at) }}</span>
            </div>
          </b-col>
          <hr/>
          <b-col cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('request')" v-if="isShowState()">
            <h3>依頼内容</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col cols="12" class="job-section-body px-0" v-if="openSection['request']">
            <b-table stacked :items="request_details" :fields="request_fields"></b-table>
          </b-col>
          <b-col v-show="isIncludeMedia('post')" cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('instagram_feed_caution')" v-if="isShowState()">
            <h3>Instagramフィードの注意点</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col v-show="isIncludeMedia('post')" cols="12" class="job-section-body px-3" v-if="openSection['instagram_feed_caution']">
            <b-row class="product-form px-3 py-3">
              <p class="caution"><b>【画像】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(applicate.instagram_caution_image)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-row class="product-form px-3 py-3">
              <p class="caution"><b>【文章】</b></p>
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(applicate.instagram_caution_caption)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-table stacked class="default-table" :items="caution_details" :fields="caution_fields">
                <template v-slot:cell(official_account)="">
                  <a class="table_link" :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>

            <b-col cols="12" class="p-2 text-center" v-show="post_state == 'not_posted'">
                <b-button @click="copyToClipboard('ハッシュタグ', applicate.must_hashtags.replaceAll(',', ' '))" class="ouvo-active-square-button my-2 mt-2 mb-3">ハッシュタグをコピーする</b-button>
            </b-col>

          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('instagram_story_caution')" v-if="isShowState()">
            <h3>ストーリーズ投稿の注意点</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col v-show="isIncludeMedia('story')" cols="12" class="job-section-body px-3"  v-if="openSection['instagram_story_caution']">
            <b-row class="product-form px-3 py-3">
              <ol class="note-field text-left">
                <li v-for="c in arraySplit(applicate.instagram_cation_story)" :key="c">{{ c }}</li>
              </ol>
            </b-row>
            <b-table stacked class="default-table" :items="story_caution_details" :fields="story_caution_fields">
                <template v-slot:cell(story_url)="data">
                  <a class="table_link"  :href="data.item.story_url">{{ data.item.story_url }}</a>
                </template>
                <template v-slot:cell(official_account)="">
                  <a class="table_link"  :href="'https://www.instagram.com/' + org.instagram_account">@{{ org.instagram_account}}</a>
                </template>
            </b-table>

            <b-col cols="12" class="p-2 text-center" v-show="post_state == 'not_posted'">
                <b-button @click="copyToClipboard('URL', applicate.story_url)" class="ouvo-square-button my-2 mt-2 mb-0">URLをコピーする</b-button>
                <b-button  @click="copyToClipboard('ハッシュタグ', applicate.must_hashtags.replaceAll(',', ' '))" class="ouvo-active-square-button my-2 mt-2 mb-3">ハッシュタグをコピーする</b-button>
            </b-col>
          </b-col>
          <b-col cols="12" class="title-header d-flex justify-content-between align-items-center" @click="onClickOpenSection('caution')" v-if="isShowState()">
            <h3>注意事項</h3>
            <b-icon icon="plus" font-scale="1.5" class="bicon"></b-icon>
          </b-col>
          <b-col cols="12" class="job-section-body px-3" v-if="openSection['caution']">
            <b-row class="product-form px-3 py-3">
                <p class="caution">{{ applicate.caution }}</p>
            </b-row>
          </b-col>


        </b-row>
      </b-container>
    </div>
    <div class="links p-0 m-0">
      <b-row>
        <b-col cols="12" class="pt-2"  v-show="(post_state == 'not_posted' && manage_state == 'delivered') || post_state == 're-post'">
          <b-button class="ouvo-active-button my-2 mt-2 mb-2"><router-link :to="{ path: '/applicationLists/' + this.$route.params.id + '/submitPost' }">投稿を報告</router-link></b-button>
        </b-col>
        <b-col cols="12" class=" pt-3 m-0">
          <b-button class="ouvo-button mb-2 ouvo-backpage-button" @click="backPage">戻る</b-button>
        </b-col>
        <b-col cols="12" class="pt-2">
          <router-link v-if="showButton(applicate.updated_at)" :to="{ path: '/applicationLists/' + this.$route.params.id + '/removeApplication' }">案件を辞退する</router-link>
        </b-col>
      </b-row>
    </div>

    <b-modal id="repost_caution" hide-footer centered>
        <div class="d-block text-center pb-4 px-3 pt-0">
          <p>Instagram投稿の下記の条件が満たされていませんでした。お手数ですが、再投稿をお願いします。</p>
          <p class="text-red">3枚以上投稿してください</p>
        </div>
    </b-modal>
  </div>
</template>

<script>
import TransparentHeader from '@/components/TransparentHeader.vue'

import axios from 'axios'
import moment from 'moment'

export default {
  name: 'ApplicationDetail',
  components: {
    TransparentHeader
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      app_state: "applied",
      manage_state: "delivered",
      post_state: "not_posted",
      applicate: {},
      opt_questions: {},
      slide: 0,
      sliding: null,
      openSection: {
        "description": false,
        "request": false,
        "instagram_feed_caution": false,
        "instagram_story_caution": false,
        "caution": false,
      },
      products: [],
      request_details: [],
      request_fields: [
          {key: 'media', label: '稼働'},
          {key: 'service', label: '商品・サービス'},
          {key: 'instagram_caution', label: 'Instagram投稿'},
          {key: 'instagram_story_caution', label: 'ストーリー投稿'},
          {key: 'post_at', label: '投稿期間'},
      ],
      org: {},
      caution_details: [],
      caution_fields: [
          {key: 'must_hashtags', label: '必須ハッシュタグ'},
          {key: 'recommend_hashtags', label: '推奨ハッシュタグ'},
          {key: 'official_account', label: '公式アカウント'},
      ],
      story_caution_fields: [
          {key: 'story_url', label: 'URL'},
          {key: 'official_account', label: '公式アカウント'},
      ],

    }
  },
  created() {



    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/applicates/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.applicate = res.data.applicate
        this.app_state = this.applicate.applicate_state
        this.manage_state = this.applicate.manage_state || "first"
        this.post_state = this.applicate.post_state || "not_posted"
        this.products = res.data.products
        this.org = res.data.org
        var m = ""

        Array.from(new Set((this.applicate.media || '').split(","))).forEach(e => {
            if(e == "story") {
              m += " * Instagramストーリー投稿\n"
            }
            if(e == "post") {
              m += " * Instagram通常投稿"
            }
        })
        this.request_details = [
            {media: m, service: this.applicate.service, experience_place: this.applicate.experience_place, experience_count: this.applicate.experience_count}
        ]
        this.caution_details = [
            {must_hashtags: this.applicate.must_hashtags, recommend_hashtags: this.applicate.recommend_hashtags, official_account: this.applicate.official_account}
        ]
        this.story_caution_details = [
            {story_url: this.applicate.story_url, official_account: this.applicate.official_account}
        ]

        this.setOpenSection()

        axios
          .get(process.env.VUE_APP_API_ENDPOINT + "/jobs/" + this.applicate.job_id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
          .then((res) => {
            this.opt_questions = res.data.opt_questions
          }).catch(error => {
            console.log(error)
          })



      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    onClickOpenSection(c) {
        this.openSection[c] = !this.openSection[c]
    },
    arraySplit(s) {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    },
    isIncludeMedia(s) {
      var st = this.arraySplit(this.applicate.media)
      if(st != undefined) {
        return (st.indexOf(s) > -1)? true : false
      }
    },
    copyToClipboard(c, text) {
      let textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.top = '-100px';
      textarea.style.maxHeight = '100px'; 
      textarea.style.position = 'absolute';
      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      this.$notify({
        group: 'notify',
        title: c + 'をコピーしました',
        text: '投稿の際に貼り付けてください',
      });

      //navigator.clipboard.writeText(text)
      //.then(() => {
      //  this.$notify({
      //    group: 'notify',
      //    title: c + 'をコピーしました',
      //    text: '投稿の際に貼り付けてください',
      //  });
      //})
      //.catch(e => {
      //  console.error(e)
      //  this.$notify({
      //    group: 'notify',
      //    title: e,
      //    text: '投稿の際に貼り付けてください',
      //  });
      //})
    },
    setOpenSection() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        this.openSection["description"] = true
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        this.openSection["request"] = true
        this.openSection["instagram_feed_caution"] = true
        this.openSection["instagram_story_caution"] = true
        this.openSection["caution"] = true
      }
    },
    backPage: function() {
      window.history.back();
    },
    isShowState() {
      if(this.app_state == 'rejected') {
        return false
      } else if(this.app_state == 'applied') {
        return true
      }
    },
    applicateClass() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "ouvo-color"
      }
    },
    manageClass() {
      if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "ouvo-color"
      }
    },
    postClass() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "ouvo-color"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "ouvo-color"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "ouvo-red"
      } else {
        return "ouvo-gray"
      }  
    },
    calcUntilDays(job) {
      var now = moment()
      return moment(job.selection_datetime).diff(now, 'days');
    },
    applicateIconPath() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return require('../../assets/icons/256/ouvo_icon_present.png')
      } else {
        return require('../../assets/icons/256/ouvo_icon_present_black.png')
      }
    },
    postIconPath() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return require('../../assets/icons/256/ouvo_icon_camera.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return require('../../assets/icons/256/ouvo_icon_smartphone.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return require('../../assets/icons/256/ouvo_icon_camera_red.png')
      } else {
        return require('../../assets/icons/256/ouvo_icon_camera_gray.png')
      } 
    },
    manageIconPath() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return require('../../assets/icons/256/ouvo_icon_box_black.png')
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return require('../../assets/icons/256/ouvo_icon_truck.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "posted") {
        return require('../../assets/icons/256/ouvo_icon_delivery_bkack.png')
      } 
    },
    manageStateText() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "出荷準備中"
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "配送中"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "配送済"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "配送済"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "配送済"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "posted") {
        return "配送済"
      } 
    },
    postStateText() {
      if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "未提出"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "提出済み"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "re-post") {
        return "再提出"
      } 
      return "未提出"
    },
    appliedStateText() {
      if(this.app_state == "applied" && this.manage_state == "first") {
        return "当選おめでとうございます。\n商品発送まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "in_transit") {
        return "商品を発送中です\n到着まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "not_posted") {
        return "商品を確認して\n指定のSNSに投稿をお願いします。"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "checking") {
        return "投稿内容を確認中です。\n完了まで今しばらくお待ちください"
      } else if(this.app_state == "applied" && this.manage_state == "delivered" && this.post_state == "posted") {
        return "投稿確認完了"
      }
    },
    isActivatePost(applicate) {
        if(applicate.applicate_state != "applied") {
          return false
        }
        if(applicate.manage_state != "delivered") {
          return false
        }
        if(applicate.post_state == "checking") {
          return false
        } else if(applicate.post_state == "not_posted") {
          return true
        }  else if(applicate.post_state == "posted") {
          return false
        }  else if(applicate.post_state == "re-post") {
          return true
        }
        return true
    },
    formatData(d) {
      return moment(d).format("MM月DD日")
    },
    showButton(status) {
      const now = moment().format()
      return (this.app_state == "submitted" && now <= moment(status).add(3, 'days').format())? true : false
    },
    getMedias(m) {
      return Array.from(new Set((m || '').split(",")))
    },
    getIconTitle(j) {
      return this.titles[j]
    },
    formatJobDate(d) {
      var a = moment(d)
      if(a.isValid()) {
          return a.format("YYYY年MM月DD日")
      } else {
          return ""
      }
    },


  }
}
</script>

<style scoped lang="scss">
img.icon{    
  width: 100%;
  height: 17rem;
  object-fit: cover;
}
p {
  text-align: left;
}
.applicate_caution {
  color: red;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
}
.applicate_state_text {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  width: 100%;
  letter-spacing: 6px;
}
.applicate_repost_text {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  width: 100%;
}
h6.descs-title{
  font-weight: 600;
}
p.job-description{
  font-size: 14px;
}
p.descs-title{
  font-weight: 600;
  margin: 0;
}
p.descs-detail{
  padding-left: 15px;
}
.descs-wrapper{
  margin-top: .3rem;
  margin-bottom: .4rem;
}
.green-btn{
  border: none !important;
  background-color: #9FDC6F !important;
}
.links{
  margin: 15px auto;
}

/deep/ .table {
  color: #575757;
  margin-bottom: 0px;
  th, td {
    padding: 0px;
    font-size: 12px;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      white-space: break-spaces;
    }
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #F0F0F0;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
/deep/ .table.default-table {
  color: #575757;
  th, td {
    padding: 0px;
    font-size: 12px;
    border: 1px solid #333;
    border-bottom: none;
    div {
      padding: 0.75rem !important;
      text-align: left !important;
      border-left: 1px solid #333;
      white-space: pre;
      white-space: break-spaces;
    }
  }
  td:first-child {
    border-top-width: 1px !important;
  }
  td:last-child {
    border: 1px solid #333;
  }
  td::before {
    padding: 0.75rem !important;
    background-color: #fff;
    text-align: left !important;
    font-weight: 400 !important;
  }
}
.top_cover_img {
  border-radius: 0px !important;
}
.half-icon {
  width: 5%;
  margin-left: 5px;
  background-color: #F2F2F2;

}

/deep/ .table_link {
  color: #8FC7ED;
}
.bicon {
  background-color: #E5E5E5;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}
.repost_small_caution {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.small_caution {
  width: 100%;
  font-size: 13px;
  text-align: center;
}
.btn.btn-secondary.btn-gray {
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  font-size: 14px;
  padding: 12px 20px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.2));
  
  a {
    color: #373E45;
    font-weight: 500;
  }
}
.thanks_p{
  color: #7FCAC0;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.banner_img {
  width: 70%;
}
.app_state_img {
  width: 85%;
}
.applied_text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  white-space: pre;
}

.app_sub_text {
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.section-title {
  background: #9BDDDB;
  color: #525252;
  font-size: 14px;
  font-weight: 600;
}
.recommend_caption {
  color: #525252;
  border-bottom: 1px solid #D2D2D2;
  text-align: left;
  font-size: 12px;
  line-height: 26px;
  width: 100%;
}
.hoshi_img {
  width: 20%;
  background: #9BDDDB;
}
.text-red {
  font-weight: 500;
  color: red;
}
/deep/ .modal-header {
  border: none !important;
}
/deep/ .modal-content {
  border-radius: 0px;
}

</style>
