<template>
  <div class="thanks">
    <b-container fluid="sm" class="job_application border_bottom">
        <b-row class="text-center">
            <b-col cols="12" class="p-0"><p class="thanks_p pt-5 mb-0 mt-2">ご応募いただき<br/>ありがとうございました</p></b-col>
            <b-col cols="12" class="p-0">
              <img class="py-4 logo_img" src="../assets/icons/256/ouvo_icon_diamond.png" alt="Kitten">
            </b-col>
            <p class="subtitle mb-0">抽選結果は、LINEにて通知されます</p>
            <p class="small_caution">案件の状況はマイページから確認できます</p>
        </b-row>
        <b-row class="company_section mt-3">
          <b-col cols="12" class="pt-4 px-3 bg-blue pt-2 text-center">
            <h2 class="section-title">企業をお気に入りに登録しお知らせ通知を受け取ろう</h2>
            <img v-if="favorite == null" @click="postFavo(organization.uuid)" class="pt-0 hoshi_img" src="../assets/ouvo_hoshi.png" alt="Kitten">
            <img v-else @click="deleteFavo(organization.uuid)" class="pt-0 hoshi_img" src="../assets/icons/menu/heart.png" alt="Kitten">
          </b-col>
          <b-col cols="2" class="py-2 px-3 text-left bg-blue">
            <img class="pt-0 hoshi_img" :src="logoImg" alt="Kitten">
          </b-col>
          <b-col cols="10" class="py-2 px-3 text-left bg-blue">
            <h2 class="company_name mb-0">{{ organization.name }}</h2>
            <a :href="organization.url"><small>{{ organization.url }}</small></a>
            <p class="company_caption">{{ organization.profile }}</p>
          </b-col>
        </b-row>
        <p class="recommend_caption pt-4">こちらもおすすめ</p>
        <b-col cols="12" class=" text-center">
          <b-button href="/"  class="ouvo-button my-2">マイページ</b-button>
        </b-col>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Application',
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken'),
      opt_questions: {},
      organization: {},
      logoImg: "",
      favorite: {}
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/jobs/" + this.$route.params.id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.opt_questions = res.data.opt_questions
        this.organization = res.data.org
        this.favorite = res.data.favorite

        if(this.organization.logo_img != null) {
          this.logoImg = this.organization.logo_img
        }
      }).catch(error => {
        console.log(error)
      })
  },
  methods: {
    postFavo(org_id) {
      axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/favorites", {id: org_id}, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then(() => {
            this.favorite = "favorite"
        }).catch(error => {
          console.log(error)
        })
    },
    deleteFavo(org_id) {
      axios
        .delete(process.env.VUE_APP_API_ENDPOINT + "/favorites/" + org_id, { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then(() => {
            this.favorite = null
        }).catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.logo_img {
  width: 24%;
}
.banner_img {
  width: 70%;
}
.hoshi_img {
  width: 20%;
  background: #9BDDDB;
}
.subtitle {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.small_caution {
  width: 100%;
  font-size: 13px;
}
.btn.btn-secondary.btn-gray {
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  font-size: 14px;
  padding: 12px 20px;
  a {
    color: #373E45;
    font-weight: 500;
  }
}
.thanks_p{
  color: #7FCAC0;
  font-size: 20px;
  font-weight: 700;
}

.bg-blue {
  background: #9BDDDB;
}
.section-title {
  background: #9BDDDB;
  color: #525252;
  font-size: 14px;
  font-weight: 600;
}
</style>
