<template>
  <div class="jobs app">
    <top_nav @switchMode="switchMode" @fetchJobs="fetchJobs" :mode="job_list_mode"></top_nav>
    <template>
      <div>
        <div class="home-container px-2">
          <b-container v-for="job in jobs" v-bind:key="job.uuid" fluid="sm" class="job-container border_bottom pt-4 mt-2">
            <b-row v-show="job_list_mode == 'gallery'" class="job-card mb-5">
              <a class="w100" :href="'jobs/' + job.uuid"><img class="job_top_img" :src="arraySplit(job.images)[0]"/></a>
              <div class="clip mt-2 mr-2">
                 <img v-if="!isClip(job)" class="clip_img" @click="onClickClipJob(job)" src="../assets/icons/menu/ouvo_hart.png" alt="Kitten">
                 <img v-if="isClip(job)" class="clip_img" @click="onClickDeleteClip(job)" src="../assets/icons/menu/heart.png" alt="Kitten">
              </div>

              <!-- <b-img class="thumbnail" :src="job.images"></b-img> -->
              <b-col cols="12" class="text-area float-left px-1">

                <b-row align-h="between">
                    <p class="tag " style="margin-bottom: 5px;">{{ job.category }}</p>
                </b-row>
                <div class="float-left job-title"><router-link :to="'jobs/' + job.uuid">{{ job.title }}</router-link></div>
                <p class="job-description">{{ job.description }}</p> 
                <div class="job-abst-form text-left">
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_insta.png"/><span>{{ job.follower }}フォロワー以上</span>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_TwoPersons.png"/><span>{{ job.monitor_num }}人</span>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_user.png"/><span>{{ job.min_age }}~{{ job.max_age }}歳</span><br/>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_clock.png"/><span>募集期限: {{ formatJobDate(job.application_end_datetime) }}締切</span><br/>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_Calendar.png"/><span>投稿期限: {{ formatJobDate(job.end_post_at) }}</span>
                    <hr/>
                    <span class="media_icon pr-1 mx-0">稼働内容: </span>
                    <span v-for="j in medias" :key="j" class="media_icon px-0 mx-0" :class="isActiveMediaIconClass(j, job)">
                       <span v-if="isActiveMediaIcon(j, job)" class="px-0 mx-0"><img class="half-icon" src="../assets/icons/32/ouvo_icon_Cbox.png"/>{{ getIconTitle(j) }}</span>
                       <span v-else class="px-0 mx-0"><img class="half-icon" src="../assets/icons/32/ouvo_icon_Cbox_gray.png"/>{{ getIconTitle(j) }}</span>
                    </span>
                </div>
              </b-col>
            </b-row>

            <b-row v-show="job_list_mode == 'list'" class="job-card mb-3">
              <!-- <b-img class="thumbnail" :src="job.images"></b-img> -->
              <b-col cols="4" class="text-area float-left px-0 pr-1">
                <img class="job_top_list_img" :src="job.images"/>
              </b-col>
              <b-col cols="8" class="text-area float-left pl-2 pr-0">
                <div class="float-left job-list-title"><router-link :to="'jobs/' + job.uuid">{{ job.title }}</router-link></div>
                <div class="job-abst-list-form ">
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_insta.png"/><span>{{ job.follower }}フォロワー以上</span>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_TwoPersons.png"/><span>{{ job.monitor_num }}人</span><br/>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_user.png"/><span>{{ job.min_age }}~{{ job.max_age }}歳</span><br/>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_clock.png"/><span>募集期限: {{ formatJobDate(job.application_end_datetime) }}締切</span><br/>
                    <img class="half-icon" src="../assets/icons/32/ouvo_icon_Calendar.png"/><span>投稿期限: {{ formatJobDate(job.end_post_at) }}</span><br/>
                    <span v-for="j in getMedias(job.media)" :key="j" v-show="j != ''">
                       <img class="half-icon" src="../assets/icons/32/ouvo_icon_Cbox.png"/>{{ getIconTitle(j) }}
                    </span>
                </div>
              </b-col>
              <hr/>
            </b-row>


          </b-container>
          <b-container v-if="jobs.length == 0" fluid="sm" class="job-container">
            <b-row class="not_found">
              <b-col cols="12">
                <p class="auto px-2 my-5 py-5">現在、条件に合う案件はありません</p>
              </b-col>
            </b-row>

          </b-container>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import top_nav from '@/components/Header.vue'

import axios from 'axios'
import moment from 'moment'

export default {
  name: 'Jobs',
  components: {
    top_nav
  },
  data() {
    return {
      jobs: [],
      authUser: '',
      filter_d: {},
      page: 1,
      clips: [],
      idToken: localStorage.getItem('idToken'),
      titles: {
        "story": "Instagram投稿",
        "post": "Instagram Story投稿",
        "twitter": "Twitter",
      },
      medias: ["story", "post", "twitter"],
      agesDict: {
          "": "",
          "10-19": "10代",
          "20-29": "20代",
          "30-39": "30代",
          "40-49": "40代",
      },
      job_list_mode: "gallery"
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/jobs", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
      .then((res) => {
        this.jobs = res.data
      }).catch(error => {
        console.log(error)
      })
    this.fetchClips()
  },
  methods: {
    isActiveMediaIcon(j, job) {
      var a = this.getMedias(job.media)
      if(a.indexOf(j) > -1) {
        return true
      } else {
        return false
      }
    },
    arraySplit(s) {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    },
    isIncludeMedia(s) {
      var st = this.arraySplit(this.job.media)
      if(st != undefined) {
        return (st.indexOf(s) > -1)? true : false
      }
    },
    isActiveMediaIconClass(j, job) {
      if(this.isActiveMediaIcon(j, job)) {
        return ""
      } else {
        return "active_media"
      }
    },
    isClip(j) {
      var r = false
      this.clips.forEach(c => {
        if(c.job_id == j.uuid) {
          r = true
        }
      })
      return r
    },
    switchMode(l) {
      this.job_list_mode = l
    },
    formatData(d) {
      return moment(d).format("YYYY/MM/DD HH:mm")
    },
    getMedias(m) {
      return Array.from(new Set((m || '').split(",")))
    },
    getIconTitle(j) {
      return this.titles[j]
    },
    formatJobDate(d) {
      var a = moment(d)
      if(a.isValid()) {
          return a.format("YYYY年MM月DD日")
      } else {
          return ""
      }
    },
    onClickDeleteClip(job) {
      axios
        .delete(process.env.VUE_APP_API_ENDPOINT + "/clips/" + job.uuid, {headers: {'Authorization': 'Bearer ' + this.idToken}})
        .then((res) => {
          console.log(res)
          this.fetchClips()
        }).catch(error => {
          console.log(error)
        })
    },
    onClickClipJob(job) {
      axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/clips", {id: job.uuid}, {headers: {'Authorization': 'Bearer ' + this.idToken}})
        .then((res) => {
          console.log(res)
          this.fetchClips()
        }).catch(error => {
          console.log(error)
        })
    },
    fetchClips() {
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/clips", { headers: {Authorization: `Bearer ${this.idToken}`, 'Content-Type': 'application/json'}})
        .then((res) => {
          this.clips = res.data
        }).catch(error => {
          console.log(error)
        })
    },
    fetchJobs(c, v, w="") {
      this.filter_d[c] = v
      var cs = Object.keys(this.filter_d)
      var vs = Object.values(this.filter_d)
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/jobs?filter_columns=" + cs.join(",") + "&filter_values=" + vs.join(",") + "&page=" + this.page + "&word=" + w, {headers: {'Authorization': 'Bearer ' + this.idToken}})
        .then((res) => {
          this.jobs = res.data
        }).catch(error => {
          console.log(error)
        })
    },
  }
}
</script>

<style scoped lang="scss">
.home-container {
  padding: 15px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.red{
  color: #d48585;
  font-weight: 600;
}
.btn a{
  color: #fff;
}
.job-container{
  padding: 0;
  border-radius: 8px;
  z-index: 99;
  background-color: #ffffff;
  width: auto;
  max-width: 100%;
}
.row{
  margin: 0;
  border-radius: 8px;
}
.border_bottom{
  //border-bottom: 1px solid #f3f2f4;
}
.text-area{
  border-radius: 8px;
}
.job-title{
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.job-list-title{
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.thumbnail{
  width: 100%;
  height: 9rem;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
}
.monitor{
  font-size: 11px;
  color: #888;
}
.links{
  margin: 15px auto;
}
.btn-apply{
  float: right;
}
.btn.btn-secondary{
  background-color: #00AAEE;
  border-color: #00AAEE;
  margin-bottom: 1rem;
}

@media (min-width: 768px){
  .job-container{
    max-width: 720px;
  }
}

@media (max-width: 768px){
  .job-card {
    .job_top_img {
      height: 230px;
      object-fit: cover;
    }
    .job_top_list_img {
      height: 100%;
      object-fit: cover;
    }
  }
}
.job-card {
  .job_top_img {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .35);
    margin-bottom: 0.8rem;
  }
  .job_top_list_img {
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .35);
    margin-bottom: 0.8rem;
  }
  .job-title a{
    color: #18191F;
    font-size: 16px;
    font-weight: 500;
  }
  .job-list-title a{
    color: #18191F;
    font-size: 13px;
    font-weight: 500;
  }
  .job-description  {
    font-size: 12px;
    text-align: left;
    margin-bottom: 0.5rem;
  }
  .job-abst-list-form {
    width: 100%;
    background-color: white;
    padding: 0px;
    text-align: left;
    line-height: 13px;

    .media_icon {
      background-color: white;
      font-size: 11px;
      font-weight: 800;
    }
    span {
      background-color: white;
      font-size: 11px;
      padding-right: 3px;
      font-weight: 800;
      margin-left: 5px;
      margin-right: 5px;
    }
    .b-icon {
      font-size: 13px;
      margin-left: 2px;
      margin-right: 2px;
      color: #335AA7;
    }
    hr {
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    }
  }
  .job-abst-form {
    width: 100%;
    background-color: #F2F2F2;
    padding: 10px;
    line-height: 17px;

    .media_icon {
      background-color: #F2F2F2;
      font-size: 11px;
      font-weight: 800;
    }
    span {
      background-color: #F2F2F2;
      font-size: 11px;
      padding-right: 3px;
      font-weight: 800;
      margin-left: 5px;
      margin-right: 5px;
    }
    .b-icon {
      font-size: 13px;
      margin-left: 2px;
      margin-right: 2px;
      color: #335AA7;
    }
    hr {
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    }
  }
}
.tag {
  padding: 4px 8px;
  margin: 0px;
  border: 1px solid #8E8E8E;
  border-radius: 30px;
  font-size: 11px;
  color: #8E8E8E;
  line-height: 1;
  padding-top: 6px;
}

.half-icon {
  width: 5%;
  margin-left: 3px;
  background-color: #F2F2F2;
}
.active_media {
  color: #CFDEE3;
}
.clip {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  position: absolute; 
  right: 15px;
}
.clip::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
}
.clip::after {
    display: block;
    font-size: 10px;
    font-weight: 800;
    top: 4px;
    transform: rotate(-45deg);
    color: #fff; 
    left: 5px;
    position: absolute;
    z-index: 101;
}
.clip_img {
  width: 35px;
  height: 35px;
}

</style>
