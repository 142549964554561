import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'

import Jobs from '@/views/Jobs.vue'
import JobDetails from '@/views/JobDetails.vue'

import Callback from '@/views/influencer/Callback.vue'
import Login from '@/views/influencer/Login.vue'
import Survey from '@/views/influencer/Survey.vue'
import Setting from '@/views/influencer/Setting.vue'
import InfluencerForm from '@/views/influencer/InfluencerForm.vue'
import Application from '@/views/influencer/Application.vue'
import ApplicationLists from '@/views/influencer/ApplicationLists.vue'
import ApplicationDetail from '@/views/influencer/ApplicationDetail.vue'
import SubmitPost from '@/views/influencer/SubmitPost.vue'
import Todo from '@/views/influencer/Todo.vue'
import Profile from '@/views/influencer/Profile.vue'
import ProfileSetting from '@/views/influencer/ProfileSetting.vue'
import Likes from '@/views/influencer/Likes.vue'
import Notifications from '@/views/influencer/Notifications.vue'
import Favorites from '@/views/influencer/Favorites.vue'
import FinishedJob from '@/views/influencer/FinishedJob.vue'
import RemoveApplication from '@/views/influencer/RemoveApplication.vue'

import SurveyThanks from '@/views/SurveyThanks.vue'
import Thanks from '@/views/Thanks.vue'
import ThanksPosting from '@/views/ThanksPosting.vue'
import Confirm from '@/views/Confirm.vue'

import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueRouter)

let user;

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      return data
    }
  }).catch ((err) => {
    console.log(err)
    return null
  })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/influencer_form',
    name: "InfluencerForm",
    component: InfluencerForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/jobs',
    name: "Jobs",
    component: Jobs
  },
  {
    path: '/jobs/:id',
    name: "JobDetails",
    component: JobDetails
  },
  {
    path: '/survey/:id',
    name: "Survey",
    component: Survey
  },
  {
    path: '/jobs/:id/application',
    name: "Application",
    component: Application,
    meta: { requiresAuth: true }
  },
  {
    path: '/survey_thanks',
    name: "SurveyThanks",
    component: SurveyThanks,
    meta: { requiresAuth: true }
  },
  {
    path: '/thankyou/:id',
    name: "Thanks",
    component: Thanks,
    meta: { requiresAuth: true }
  },
  {
    path: '/todo',
    name: "Todo",
    component: Todo,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/profile_setting',
    name: "ProfileSetting",
    component: ProfileSetting,
    meta: { requiresAuth: true }
  },
  {
    path: '/setting',
    name: "Setting",
    component: Setting,
    meta: { requiresAuth: true }
  },
  {
    path: '/likes',
    name: "Likes",
    component: Likes,
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: "Notifications",
    component: Notifications,
    meta: { requiresAuth: true }
  },
  {
    path: '/finished_job',
    name: "FinishedJob",
    component: FinishedJob,
    meta: { requiresAuth: true }
  },
  {
    path: '/favorites',
    name: "Favorites",
    component: Favorites,
    //meta: { requiresAuth: true }
  },
  {
    path: '/applicationLists',
    name: "ApplicationLists",
    component: ApplicationLists,
    meta: { requiresAuth: true }
  },
  {
    path: '/applicationLists/:id',
    name: "ApplicationDetail",
    component: ApplicationDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/applicationLists/:id/submitPost',
    name: "SubmitPost",
    component: SubmitPost,
    meta: { requiresAuth: true }
  },
  {
    path: '/applicationLists/:id/removeApplication',
    name: "RemoveApplication",
    component: RemoveApplication,
    meta: { requiresAuth: true }
  },
  {
    path: '/thanksposting',
    name: "ThanksPosting",
    component: ThanksPosting,
    meta: { requiresAuth: true }
  },
  {
    path: '/confirm',
    name: "Confirm",
    component: Confirm,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser();
    if (!user) {
      next({ path: '/login' });
    }
    return next()
  }
  return next()
})

// リダイレクト設定
/* router.beforeEach((to, from, next) => {
  cognito.isAuthenticated()
    .then(() => {
      console.log('hi')
      next()
    })
    .catch(() => {
      console.log('next')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
}) */

export default router

