<template>
  <div class="thanks">
    <top_nav></top_nav>
    <b-container>
        <b-card class="border-title mb-2" title="辞退完了">
          <b-card-text>応募の辞退が完了しました。</b-card-text>
          <b-card-text>またのご応募をお待ちしております。</b-card-text>
          <b-button class="btn-gray mb-3 mt-3"><router-link :to="{ path: '/' }">マイページへ戻る</router-link></b-button>
        </b-card>
    </b-container>
  </div>
</template>

<script>
//import axios from 'axios'
import top_nav from '@/components/Header.vue'

export default {
  name: 'Confirm',
  components: {
    top_nav
  },
  created() {
  },
  data() {
    return{
      authUser: "",
      idToken: localStorage.getItem('idToken')
    }
  },
  methods: {
  }
}
</script>

<style scoped>
p {
  font-size: 14px;
}
.btn.btn-secondary.btn-gray {
  background-color: #A6B2B7;
  border-color: #A6B2B7;
}
.card{
  margin-top: 6rem;
}
.card.border-title{
  border: none;
}

.links{
  margin: 15px auto;
}
</style>
